import React, { useEffect } from "react";
import { upperContainer } from "../EmployeeDetails/EmployeeDetailsStyle";
import {
  Card,
  Grid,
  Box,
  Typography,
  Button,
  CardContent,
  CardActionArea,
} from "@mui/material";
import image from "../../demo.jpg";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import * as XLSX from "xlsx";
import PaginationCard from "../EmployeeDetails/Pagination";
import { theme } from "../../Theme";
import SelectSmall from "../EmployeeDetails/DetailSelector";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
const HrDetailsPage = () => {
  const selectedManager = useSelector((state) => state.manager.selectedManager);
  const [value, setValue] = useState(selectedManager);
  console.log("my data yes!", value);
  const [departmentNames, setDepartmentNames] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const storedManagerId = localStorage.getItem("selectedManagerId");
    if (storedManagerId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/userinfo/${storedManagerId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          setValue(response.data);
          console.log(response.data,"response.data")
          // Assuming value.departments is an array of department IDs
          const departmentIds = [value.department_id];
          console.log(departmentIds,"department_id")
          // Use the department data you fetched from the API
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/departments/`, {
              headers: {
                Authorization: `${token}`,
              },
            })
            .then((departmentResponse) => {
              const departmentData = departmentResponse.data;
              // Map department IDs to names
              const departmentNames = departmentIds.map((id) => {
                const department = departmentData.find((d) => d.id === id);
                return department ? department.name : "";
              });
              setDepartmentNames(departmentNames);
            })
            .catch((error) => {
              console.log(
                "Error occurred while fetching department data:",
                error
              );
            });
        })
        .catch((error) => {
          console.log("Error occurred while fetching manager data:", error);
        });
    }
  }, []);
  if (!value) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  }
  const generateExcel = () => {
    const managerData = [
      {
        Name: value.firstname + " " + value.lastname,
        Role: value.position,
        "Phone Number": value.phone_number,
        Email: value.email,
        Departments: departmentNames.join(", "),
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(managerData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Manager Info");
    XLSX.writeFile(workbook, "manager_data.xlsx");
  };
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        {" "}
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
            Manager Details
          </Typography>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            p={"1rem"}
            sx={upperContainer}
          >
            <Box
              width={"97%"}
              justifyContent={"center"}
              sx={{ marginInline: "auto" }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                color={theme.palette.primary.main}
              >
                <div style={{ display: "flex", alignitems: "center" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "40px",
                      backgroundColor: theme.palette.primary.main,
                      marginRight: "10px",
                      borderRadius: "2.5rem",
                    }}
                  ></div>
                  <Typography variant="h4">Manager Details</Typography>
                </div>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <SelectSmall />
                  <Button
                    onClick={generateExcel}
                    variant="contained"
                    sx={{ marginLeft: ".5rem", padding: ".5rem 3rem" }}
                  >
                    DownLoad Info
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                mt={2}
                sx={{
                  width: "80%",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    xl: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "10rem", // Adjust size as needed
                    height: "10rem", // Adjust size as needed
                    borderRadius: "50%",
                    backgroundColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image} // Replace with the actual image URL
                    alt="user"
                    style={{
                      width: "100%", // Make the image fill the circle
                      height: "100%", // Make the image fill the circle
                      borderRadius: "50%", // Make the image circular
                      objectFit: "cover", // Ensure the image covers the entire circle
                    }}
                  />
                </Box>
                <Box
                  flex={2}
                  p={2}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  color={theme.palette.primary.main}
                >
                  <Typography variant="h4">
                    {value.firstname + " " + value.lastname}
                  </Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography variant="h6">Role</Typography>
                      <Typography variant="span">{value.position}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Phone Number</Typography>
                      <Typography variant="span">
                        {value.phone_number}
                      </Typography>
                    </Box>{" "}
                    <Box>
                      <Typography variant="h6">Email</Typography>
                      <Typography variant="span ">{value.email}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Departments</Typography>
                      <Typography variant="span ">
                        {departmentNames.map((e) => (
                          <li>{e || "No Departments"}</li>
                        ))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                mt={4}
              >
                {[1, 2, 3, 4].map((element) => (
                  <Card
                    style={{
                      borderRadius: "1.5rem",
                      padding: ".5rem 2rem",
                      margin: ".3rem",
                      width: "24%",
                      background: theme.palette.primary.main,
                    }}
                  >
                    <CardActionArea>
                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <Box sx={{}}>
                          <DoubleArrowIcon />
                        </Box>
                        <Box style={{ flexGrow: 1, textAlign: "center" }}>
                          <Typography gutterBottom variant="h5" component="div">
                            309
                          </Typography>
                          <Typography variant="body2">
                            Total Attendance
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              borderRadius: "2rem",
              backgroundColor: "white",
              boxShadow: "0px 0px 7px grey",
              marginTop: "2rem",
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <PaginationCard />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
export default HrDetailsPage;