import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEmployee: null,
  selectedEmployeeId: null,
};

const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
      state.selectedEmployeeId = action.payload ? action.payload.employeeId : null;
    },
    resetSelectedEmployee: (state) => {
      state.selectedEmployee = null;
      state.selectedEmployeeId = null;
    },
  },
});

export const { setSelectedEmployee, resetSelectedEmployee } =
  employeesSlice.actions;

export default employeesSlice.reducer;
