import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectSmall() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
      <InputLabel id="demo-select-small-label">Select</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Select"
        onChange={handleChange}
      >
        <MenuItem value={10}>This Week</MenuItem>
        <MenuItem value={20}>This Month </MenuItem>
        <MenuItem value={30}>This Year</MenuItem>
      </Select>
    </FormControl>
  );
}
