import React from "react";
import { MainLoginDiv } from "./LoginStyle";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { Grid } from "@mui/material";

const Login = () => {
  return (
    <MainLoginDiv>
      <Grid container>
        <Grid container justifyContent={"center"}>
          {<LoginForm></LoginForm>}
        </Grid>
      </Grid>
    </MainLoginDiv>
  );
};

export default Login;
