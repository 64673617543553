import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const AcceptRejectDialog = ({
  open,
  onClose,
  UserLeaveId,
  updateStatusInUI,
}) => {
  const [userSelection, setUserSelection] = useState(null);

  const handleAccept = () => {
    setUserSelection("Accepted");
    updateStatus("Accepted");
  };

  const handleReject = () => {
    setUserSelection("Rejected");
    updateStatus("Rejected");
  };

  const updateStatus = (newStatus) => {
    setUserSelection(newStatus);
    const token = localStorage.getItem("token");

    const updatedData = {
      status: newStatus,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/leave/${UserLeaveId}`,
        updatedData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(() => {
        toast.success(`Leave ${newStatus} successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        onClose();
        updateStatusInUI(UserLeaveId, newStatus);
      })
      .catch((error) => {
        console.error("Error updating request:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Accept or Reject Leave Request</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to accept or reject this leave request?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button onClick={handleAccept} variant="contained" color="primary">
          Accept
        </Button>
        <Button onClick={handleReject} variant="contained" color="primary">
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptRejectDialog;
