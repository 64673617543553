import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#011538",
    },
    secondary: {
      main: "#ffffff",
    },
    additionalColor: {
      main: "#29559a", // Add a new color
    },
    SearchBarColor: {
      main: "white", // Add a new color
    },
    deleteIcon: {
      main: "#E74C2D", // Add a new color
    },
    editIcon: {
      main: "blue", // Add a new color
    },
  },
  typography: {
    fontFamily: "Helvetica", // Replace "Your Font Family" with the actual font you want to use
  },

  components: {
    MuiLink: {
      root: {
        color: "white", // Change link color
        textDecoration: "none", // Remove underline
        "&:hover": {
          color: "#00004e",
          backgroundColor: "purple", // Change background color on hover
        },
      },
    },
     MuiAppBar: {
      styleOverrides: {
        root: {
          background: "linear-gradient(0.7turn, #55d7b3, #203341)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem", // Adjust font size as needed

          marginBottom: "8px", // Adjust margin as needed
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "pink", // Default link color
            textDecoration: "none", // Remove underline
            "&:hover": {
              color: "#00004e",
              backgroundColor: "pink",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              color: "SearchBarColor", // Text color for the focused label
            },
            "& label.Mui": {
              color: "SearchBarColor", // Text color for the focused label
            },
            "& .MuiInputBase-input": {
              color: "SearchBarColor", // Text color inside the input field
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "SearchBarColor",
                height: "40rem", // Border color for the TextField
              },
              "&:hover fieldset": {
                borderColor: "SearchBarColor", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "SearchBarColor", // Border color when focused
              },
            },
          },
          input: {
            // Adjust placeholder alignment
            textAlign: "center", // or 'left' if needed
          },
        },
      },
    },
  },
});
