import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import { theme } from "../../Theme";

const Graph = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 380,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: "Attendance",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " employees";
        },
      },
    },
    colors: ["#64CD89", "#011538", "#5BB4F6"],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/getWeeklyAttendanceSummary`;
        const response = await axios.get(apiUrl);
        const weeklyData = response.data;

        const categories = weeklyData.present.map(dayData => dayData.date);
        setOptions(prevOptions => ({ ...prevOptions, xaxis: { ...prevOptions.xaxis, categories } }));

        setSeries([
          {
            name: "Present",
            data: weeklyData.present.map(dayData => dayData.count),
          },
          {
            name: "Absent",
            data: weeklyData.absent.map(dayData => dayData.count),
          },
          {
            name: "Leave",
            data: weeklyData.leave.map(dayData => dayData.count),
          },
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  }, [options]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "1rem",
        boxShadow: "0 10px 10px #0000004d",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          mt={2}
          ml={2}
          p={3}
          sx={{ fontWeight: 600, width: "70%" }}
          variant="h5"
        >
         Previous Week Attendance 
        </Typography>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <div id="chart">
          <ApexCharts options={options} series={series} type="bar" height={380} />
        </div>
      )}
    </Box>
    );
  };
  
  export default Graph
