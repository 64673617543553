import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DashBoard from "./Components/Dashboard/Dashboard";
import Settings from "./Components/Settings/Settings";
import ForgotPage from "./Components/ForgotPassword/ForgotPage";
import Login from "./Pages/Login/Login";
import SignUpForm from "./Components/SignUp/SignUpMainDiv";
import EmployeeDetailsPage from "./Components/EmployeeDetails/EmployeeDetailsPage";
import EmployeeDetailsPageOwn from "./Components/EmployeeDetails/EmployeeDetailsPageOwn";

import { Box } from "@mui/material";
import DataTables from "./Components/AttendanceReport/AttendanceReport";
import { ToastContainer } from "react-toastify";

import EmployeeManagementTable from "./Components/AttendanceReport/AttendanceReport";
import AttendanceEmployeeReport from "./Components/AttendanceReport/AttendanceEmployeeReport";
import StudentManagementTable from "./Components/EmployeeDetails/AllEmployee";
import EmployeeForm from "./Components/EmployeeDetails/AddEmployee";
import EditEmployeeForm from "./Components/EmployeeDetails/EditEmployee";

import LeaveRequestTableAdmin from "./Components/LeaveManagement/AllLeaveAdmin";
import LeaveRequestTableHR from "./Components/LeaveManagement/AllLeaveAdminHR";
import LeaveBalanceTable from "./Components/LeaveManagement/LeaveBalance";
import LeaveTypePage from "./Components/LeaveManagement/LeaveType";

import EmployeeAttendance from "./Components/AttendanceReport/Employee-Attendance";
import AttendanceSheet from "./Components/AttendanceReport/AttendanceSheet";
import HolidaysTable from "./Components/Holidays/AllHolidays";
import AddHolidayForm from "./Components/Holidays/AddHoliday";
import EmployeeDashoboard from "./Components/Dashboard/EmployeeDashoboard";

import ProtectedRoutes from "./auth/ProtectedRoutes";

import HRManagementTable from "./Components/HRCrud/AllHr";
import HrEditForm from "./Components/HRCrud/EditHr";
import HrDetailsPage from "./Components/HRCrud/Hrdetails";

///////
import CompanyManagementTable from "./Components/Company/AllCompanies";
import CompanyEditForm from "./Components/Company/EditCompanies";
import CompanyDetailsPage from "./Components/Company/Companiesdetails";
import CompanyManagerForm from "./Components/Company/CompanyAdd";
///////
import DepartmentManagementTable from "./Components/Department/AllDepartment";
import DepartmentEditForm from "./Components/Department/EditDepartment";
import DepartmentDetailsPage from "./Components/Department/DepartmentDetails";
import DepartmentManagerForm from "./Components/Department/AddDepartment";

import ManagerForm from "./Components/HRCrud/AddHr";
import EmployeeCard from "./Components/EmployeeDetails/EmployeeDetails";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedResponse } from "./redux/role/roleSlice";
import { useEffect, useState } from "react";
import EmployeeLeavePage from "./Components/EmployeeDetails/EmployeeLeaves";
import EmployeeSettings from "./Components/Settings/EmployeeSettings";

function App() {
  const selectedResponse = useSelector((state) => state.role.selectedResponse);
  // const [role, setRole] = useState(selectedResponse?.role);
  const [role, setRole] = useState("");
  const value = JSON.parse(localStorage.getItem("user_info"));
  useEffect(() => {
    if (value) {
      setRole(value.role);
      // setRole("employee");
    }
  }, [value]);

  const checkUserInfo = JSON.parse(localStorage.getItem("user_info"));
  if (!checkUserInfo) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Box
          sx={{
            display: "flex",
            width: { xs: "fit-content", md: "100%" },
            boxSizing: "border-box",
            background: "#f8f8f8",
            height: "100%",
          }}
        >
          {/* <Box
            component="main"
            sx={{ flexGrow: 1, }}
          ></Box> */}

          <Routes>
            {role === "employee" ? (
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<EmployeeDashoboard />} />
                <Route path="/details" element={<EmployeeDetailsPageOwn />} />
                <Route path="/leaves" element={<EmployeeLeavePage />} />
                <Route
                  path="/attendance"
                  element={<AttendanceEmployeeReport />}
                />
                {/* <Route path="/my-settings" element={<EmployeeSettings />} /> */}
              </Route>
            ) : role === "admin" ? (
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<DashBoard />} />
                <Route path="/detail" element={<EmployeeDetailsPage />} />
                <Route
                  path="/employee-details"
                  element={<EmployeeDetailsPage />}
                />
                <Route
                  path="/all-employee"
                  element={<StudentManagementTable />}
                />
                <Route path="/add-employee" element={<EmployeeForm />} />
                <Route path="/edit-employee" element={<EditEmployeeForm />} />
                <Route
                  path="/employee-detail"
                  element={<EmployeeDetailsPage />}
                />

                <Route path="/leave-balance" element={<LeaveBalanceTable />} />
                <Route
                  path="/attendance-report"
                  element={<EmployeeManagementTable />}
                />
                <Route
                  path="/all-leaves"
                  element={<LeaveRequestTableAdmin />}
                />
                <Route path="/leaves-type" element={<LeaveTypePage />} />
                <Route
                  path="/employee-attendance"
                  element={<EmployeeAttendance />}
                />
                <Route path="/attendance-sheet" element={<AttendanceSheet />} />
                <Route path="/all-holidays" element={<HolidaysTable />} />
                <Route path="/add-holiday" element={<AddHolidayForm />} />
                <Route
                  path="/employee-dashboard"
                  element={<EmployeeDashoboard />}
                />
                <Route path="/all-hr" element={<HRManagementTable />} />
                <Route path="/add-hr" element={<ManagerForm />} />
                <Route path="/edit-hr" element={<HrEditForm />} />
                <Route path="/detail-hr" element={<HrDetailsPage />} />
                <Route
                  path="/all-companies"
                  element={<CompanyManagementTable />}
                />
                <Route path="/add-company" element={<CompanyManagerForm />} />
                <Route path="/edit-company" element={<CompanyEditForm />} />
                <Route
                  path="/detail-company"
                  element={<CompanyDetailsPage />}
                />

                <Route
                  path="/all-departments"
                  element={<DepartmentManagementTable />}
                />
                <Route
                  path="/add-department"
                  element={<DepartmentManagerForm />}
                />
                <Route
                  path="/edit-department"
                  element={<DepartmentEditForm />}
                />
                <Route
                  path="/detail-department"
                  element={<DepartmentDetailsPage />}
                />
                <Route path="/settings" element={<Settings />} />
              </Route>
            ) : role === "hr" ? (
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<DashBoard />} />
                <Route path="/detail" element={<EmployeeDetailsPage />} />
                <Route
                  path="/employee-details"
                  element={<EmployeeDetailsPage />}
                />
                <Route
                  path="/all-employee"
                  element={<StudentManagementTable />}
                />
                <Route path="/add-employee" element={<EmployeeForm />} />
                <Route path="/edit-employee" element={<EditEmployeeForm />} />
                <Route
                  path="/employee-detail"
                  element={<EmployeeDetailsPage />}
                />
                <Route path="/com" element={<EmployeeCard />} />
                <Route path="/leave-balance" element={<LeaveBalanceTable />} />
                <Route
                  path="/attendance-report"
                  element={<EmployeeManagementTable />}
                />
                <Route path="/all-leaves" element={<LeaveRequestTableHR />} />
                <Route path="/leaves" element={<EmployeeLeavePage />} />
                {/* <Route path="/leaves-type" element={<LeaveTypePage />} /> */}
                <Route
                  path="/employee-attendance"
                  element={<EmployeeAttendance />}
                />
                <Route path="/attendance-sheet" element={<AttendanceSheet />} />
                <Route path="/all-holidays" element={<HolidaysTable />} />
                <Route path="/add-holiday" element={<AddHolidayForm />} />
                <Route
                  path="/employee-dashboard"
                  element={<EmployeeDashoboard />}
                />

                <Route
                  path="/all-companies"
                  element={<CompanyManagementTable />}
                />
                <Route path="/add-company" element={<CompanyManagerForm />} />
                <Route path="/edit-company" element={<CompanyEditForm />} />
                <Route
                  path="/detail-company"
                  element={<CompanyDetailsPage />}
                />
                <Route
                  path="/all-departments"
                  element={<DepartmentManagementTable />}
                />
                <Route
                  path="/add-department"
                  element={<DepartmentManagerForm />}
                />
                <Route
                  path="/edit-department"
                  element={<DepartmentEditForm />}
                />
                <Route
                  path="/detail-department"
                  element={<DepartmentDetailsPage />}
                />
              </Route>
            ) : null}

            <Route path="/login" element={<Login />} />
            <Route path="/SignUp" element={<SignUpForm />} />
          </Routes>
        </Box>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
