import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

export default function ColorTabs() {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100%",

        boxShadow: 3,
        marginTop: "2rem",
        borderRadius: ".5rem",
      }}
    >
      <Tabs
        sx={{}}
        value={value}
        onChange={handleChange}
        textColor="primary"
        // indicatorColor="pink"
        aria-label="secondary tabs example"
        variant="fullWidth" // Set the variant to fullWidth
      >
        <Tab value="one" label="About" />
        <Tab value="two" label="Skills" />
      </Tabs>

      <div
        role="tabpanel"
        hidden={value !== "one"}
        style={{
          transition: "opacity 0.3s ease-in-out",
          opacity: value === "one" ? 1 : 0,
        }}
      >
        <Box p={3}>
          <Typography sx={{ fontSize: ".8rem" }} variant="body1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias
            magnam enim possimus vero, dolorum vel hic. Qui, repellat a? Magni
            nulla, asperiores quod, dignissimos fuga consequatur nihil
            reprehenderit sunt voluptate ea debitis, laborum reiciendis totam
            provident qui cumque necessitatibus eum!
          </Typography>
          <Typography sx={{ fontSize: ".7rem", color: "blue" }}>
            Email Address:-
          </Typography>
          <Typography variant="body1">FaisalHaroon500@gmail.com</Typography>
          <Divider />
          <Typography sx={{ fontSize: ".7rem", color: "blue" }} variant="body1">
            Phone:-{" "}
          </Typography>
          <Typography variant="body1">0305-4052725</Typography> <Divider />
        </Box>
      </div>

      <div
        role="tabpanel"
        hidden={value !== "two"}
        style={{
          transition: "opacity 0.3s ease-in-out",
          opacity: value === "two" ? 1 : 0,
        }}
      >
        <Box p={3}>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui facere
            illo rem eos ullam fugiat deserunt quaerat ducimus beatae? Adipisci
            cum quod id consequuntur alias necessitatibus iste molestias,
            repellendus architecto.
          </Typography>
        </Box>
      </div>
    </Box>
  );
}
