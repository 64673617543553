import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Radio,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

import { theme } from "../../Theme";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSelectedDepartment } from "../../redux/departments/departmentSlice";

const StudentManagementTable = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchManagerData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/departments`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        // Extract the "name" field from the response data
        const departmentNames = response?.data;
        setEmployeeData(departmentNames);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    }

    fetchManagerData();
  }, []);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // const handleRadioChange = (studentId) => {
  //   setSelectedStudent(studentId);
  // };

  // const handleCheckboxChange = (studentId) => {
  //   setSelectedStudents((prevSelected) =>
  //     prevSelected.includes(studentId)
  //       ? prevSelected.filter((id) => id !== studentId)
  //       : [...prevSelected, studentId]
  //   );
  // };
  const handleEditClick = (employee) => {
    dispatch(setSelectedDepartment(employee));
    navigate("/edit-department");
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteClick = (employee) => {
    setCurrentDepartment(employee);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = async () => {
    const id = currentDepartment.id;
    setIsDeleteModalOpen(false);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/departments/${id}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log("Department deleted successfully!");
      toast.success("Department deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmployeeData((prevData) => prevData.filter((emp) => emp.id !== id));
    } catch (error) {
      console.error("Error deleting Department:", error);
    }
  };

  const filteredEmployeeData = employeeData?.filter((employee) =>
  employee.name.toLowerCase().includes(searchQuery.toLowerCase())
);  
console.log('filteredEmployeeData:', filteredEmployeeData);
  return (
    <>
    <Grid
    container
    justifyContent={"center"}
    p={"1rem"}
    gap={5}
    width={"100vw"}
    sx={{
      marginInline: "auto",
      marginTop: "5rem",
    }}
  >
    <Box sx={{ width: "100%", marginInline: "auto" }}>
      <Typography variant="h5">All Departments</Typography>
      <Paper sx={{ marginTop: "2rem" }}>
        <AppBar position="static" color="primary">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: {
                xs: "end",
                sm: "space-between",
              },
            }}
          >
            <Typography
              sx={{
                marginRight: "2rem",
                color: "white",
                display: {
                  xs: "none",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              variant="h6"
            >
              All Department
            </Typography>
            <TextFieldStyled
              label="Search"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              value={searchQuery}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    sx={{ color: theme.palette.secondary.main }}
                  />
                ),
              }}
            />
          </Toolbar>
        </AppBar>

        <TableContainer>
          <Table sx={{ marginLeft: ".5rem" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                  Clock In Time
                </TableCell>
                <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                  Clock Out Time
                </TableCell>
                <TableCell sx={{ fontWeight: 600, paddingLeft: "2rem" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredEmployeeData
              ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((employee, index) => (
                <TableRow key={index}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {new Date(employee?.starting_time).toLocaleTimeString(
                      "en-US",
                      {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {new Date(employee?.ending_time).toLocaleTimeString(
                      "en-US",
                      {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(employee)}>
                      <Tooltip title="Edit " arrow>
                        <EditIcon
                          sx={{ color: theme.palette.editIcon.main }}
                        />
                      </Tooltip>
                    </IconButton>
                    <IconButton>
                      <Tooltip title="Delete " arrow>
                        <DeleteOutlineIcon
                          sx={{ color: theme.palette.deleteIcon.main }}
                          onClick={() => handleDeleteClick(employee)}
                        />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEmployeeData?.length || 0} // Update count based on your data length
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </TableContainer>
      </Paper>
    </Box>
  </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentManagementTable;
