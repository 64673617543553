import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";

const EditLeaveTypeDialog = ({ open, onClose, updateLeaveRequests, leaveTypeToEdit }) => {
  const [leave_name, setleave_name] = useState("");
  const [leave_type, setleave_type] = useState("");
  const [leave_units, setleave_units] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Set initial values when the modal is opened for editing
    if (open && leaveTypeToEdit) {
      setleave_name(leaveTypeToEdit.leave_name || "");
      setleave_type(leaveTypeToEdit.leave_type || "");
      setleave_units(leaveTypeToEdit.leave_units || "");
      setSelectedStatus(leaveTypeToEdit.status || "Pending");
    }
  }, [open, leaveTypeToEdit]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleEditLeaveType = async () => {
    if (!leave_name || !leave_type || !leave_units) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    const editedLeaveTypeData = {
      leave_name,
      leave_type,
      leave_units,
      status: selectedStatus,
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/leaveType/${leaveTypeToEdit.id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedLeaveTypeData),
        }
      );
  
      if (response.status === 200) {
        toast.success("Leave Type edited successfully!");
  
        // Update the leave request in the parent component immediately
        updateLeaveRequests((prevLeaveRequests) => {
          const updatedLeaveRequests = prevLeaveRequests.map((leaveRequest) =>
            leaveRequest.id === leaveTypeToEdit.id ? editedLeaveTypeData : leaveRequest
          );
          return updatedLeaveRequests;
        });
  
        onClose();
      } else {
        toast.error("Failed to edit Leave Type. Please try again later.");
      }
    } catch (error) {
      console.error("Error editing Leave Type:", error);
      toast.error("An error occurred while editing Leave Type. Please try again later.");
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Leave Type
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={6}>
            <TextField
              id="leave_name"
              label="Leave Name"
              fullWidth
              required
              value={leave_name}
              onChange={(e) => setleave_name(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="leave_type"
              label="Leave Type"
              fullWidth
              required
              value={leave_type}
              onChange={(e) => setleave_type(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="leave_units"
              label="Leave Units"
              fullWidth
              required
              value={leave_units}
              onChange={(e) => setleave_units(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="status"
              label="Status"
              select
              fullWidth
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Accepted">Accepted</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Button
                onClick={handleEditLeaveType}
                color="primary"
                variant="contained"
              >
                Save Changes
              </Button>
              <Button
                onClick={onClose}
                color="error"
                variant="contained"
                sx={{ marginLeft: "1rem" }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditLeaveTypeDialog;
