import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Radio,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import { theme } from "../../Theme";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import axios from "axios";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { setSelectedCompany } from "../../redux/companies/companySlice";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const StudentManagementTable = () => {
  const [employeeData, setEmployeeData] = useState([]);

  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  useEffect(() => {
    async function fetchManagerData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/branch`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        setEmployeeData(response?.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    }

    fetchManagerData();
  }, []);
  // const handleChangePage = (_, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleRadioChange = (studentId) => {
    setSelectedStudent(studentId);
  };
  const NavigatingPage = () => {
    navigate("/add-company");
  };

  // const handleCheckboxChange = (studentId) => {
  //   setSelectedStudents((prevSelected) =>
  //     prevSelected.includes(studentId)
  //       ? prevSelected.filter((id) => id !== studentId)
  //       : [...prevSelected, studentId]
  //   );
  // };
  const handleEditClick = (employee) => {
    dispatch(setSelectedCompany(employee));
    navigate("/edit-company");
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteClick = (employee) => {
    setCurrentCompany(employee);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = async () => {
    const id = currentCompany.id;
    setIsDeleteModalOpen(false);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/branch/${id}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log("Branch deleted successfully!");
      toast.success("Branch delete successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setEmployeeData((prevData) => prevData.filter((emp) => emp.id !== id));
    } catch (error) {
      console.error("Error deleting Branch:", error);
    }
  };
  const filteredEmployeeData = employeeData.filter((employee) =>
  employee.name.toLowerCase().includes(searchQuery.toLowerCase())
);

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5">All Branches</Typography>
          <Paper sx={{ marginTop: "2rem" }}>
            <AppBar position="static" color="primary">
              <Toolbar sx={{ display: "flex", justifyContent: { xs: "end" } }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  All Branches{" "}
                </Typography>
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />
                <IconButton color="inherit" onClick={NavigatingPage}>
                  <Tooltip title="Add new Company" arrow>
                    <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Address</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Phone Number
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Email
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Website
                    </TableCell>
                
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEmployeeData?.map((employee) => (
                    <TableRow key={employee.id}>
                      {/* <TableCell>
                        <Radio
                          checked={employee.id === selectedStudent}
                          onChange={() => handleRadioChange(employee.id)}
                        />
                      </TableCell> */}
                      <TableCell>
                        <Typography>{employee.name}</Typography>
                      </TableCell>
                      <TableCell>{employee.address}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {employee.phone_number}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {employee.email}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {employee.website}
                      </TableCell>
                     
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton onClick={() => handleEditClick(employee)}>
                          <Tooltip title="Edit" arrow>
                            <EditIcon
                              sx={{ color: theme.palette.editIcon.main }}
                            />
                          </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(employee)}>
                          <Tooltip title="Delete" arrow>
                            <DeleteOutlineIcon
                              sx={{ color: theme.palette.deleteIcon.main }}
                            />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentManagementTable;
