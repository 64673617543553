import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
} from "@mui/material";
import { theme } from "../../Theme";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  holidays_number: Yup.string().required("Holiday Number is required"),
  holidays_name: Yup.string().required("Holiday Name is required"),
  holidays_date: Yup.string().required("Holiday Date is required"),
  holidays_location: Yup.string().required("holidays_location is required"),
  shift: Yup.string().required("Shift is required"),
  details: Yup.string().required("Details are required"),
});
const token = localStorage.getItem("token");


const AddHolidayForm = () => {
  const formik = useFormik({
    initialValues: {
      holidays_number: "",
      holidays_name: "",
      holidays_date: "",
      holidays_location: "",
      shift: "",
      details: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      // Send a POST request to your backend API
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/holiday/`, values, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          // Handle success (e.g., redirect or show a success message)
          console.log("Holiday added successfully:", response.data);
          toast.success("Holiday added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          resetForm();
        })
        .catch((error) => {
          // Handle error (e.g., display an error message)
          console.error("Error adding holiday:", error);
        });
    },
  });
const navigate = useNavigate()

  const handleReset = () => {
    formik.resetForm();
    navigate("/all-holidays")
  }

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
            Add Holiday
          </Typography>
          <Paper
            sx={{
              padding: "2rem",
              marginBottom: "2rem",
              backgroundColor: theme.palette.secondary.main,
              boxShadow: " 0 10px 10px #0000004d",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Typography variant="h6" gutterBottom>
                New Holiday
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Holiday Number"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    {...formik.getFieldProps("holidays_number")}
                    error={
                      formik.touched.holidays_number &&
                      Boolean(formik.errors.holidays_number)
                    }
                    helperText={
                      formik.touched.holidays_number &&
                      formik.errors.holidays_number
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Holiday Name"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    {...formik.getFieldProps("holidays_name")}
                    error={
                      formik.touched.holidays_name &&
                      Boolean(formik.errors.holidays_name)
                    }
                    helperText={
                      formik.touched.holidays_name && formik.errors.holidays_name
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="date" // Set the input type to "date"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    {...formik.getFieldProps("holidays_date")}
                    error={
                      formik.touched.holidays_date &&
                      Boolean(formik.errors.holidays_date)
                    }
                    helperText={
                      formik.touched.holidays_date && formik.errors.holidays_date
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="holidays_location"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    {...formik.getFieldProps("holidays_location")}
                    error={
                      formik.touched.holidays_location && Boolean(formik.errors.holidays_location)
                    }
                    helperText={
                      formik.touched.holidays_location && formik.errors.holidays_location
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Shift</InputLabel>
                    <Select
                      {...formik.getFieldProps("shift")}
                      label="Shift"
                      color="primary"
                    >
                      <MenuItem value="morning">Morning</MenuItem>
                      <MenuItem value="afternoon">Afternoon</MenuItem>
                      <MenuItem value="evening">Evening</MenuItem>
                    </Select>
                    {formik.touched.shift && formik.errors.shift ? (
                      <Typography variant="body2" color="error">
                        {formik.errors.shift}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Details"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    color="primary"
                    {...formik.getFieldProps("details")}
                    error={
                      formik.touched.details && Boolean(formik.errors.details)
                    }
                    helperText={formik.touched.details && formik.errors.details}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginRight: "1rem" }}
                >
                  Add Holiday
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReset}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

export default AddHolidayForm;
