import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Input,
  InputLabel,
  FormControl,
  Avatar,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DateCalendarServerRequest from "./DateCalendarValue";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DashboardDetailPortion = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [activeTab, setActiveTab] = useState("LoggedIn");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [departmentStartTimes, setDepartmentStartTimes] = useState({});

  const navigate = useNavigate();

  const handleEmployeeClick = (employeeId) => {
    localStorage.setItem('selectedEmployeeId', employeeId);
    navigate('/employee-detail');
  };


  const [todaysLogins, setTodaysLogins] = useState({
    totalLogins: 0,
    onTime: 0,
    late: 0
  });

  useEffect(() => {
    const fetchTodaysLogins = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dbAttendance/checkTodaysLogins`);
        setTodaysLogins(response.data);
      } catch (error) {
        console.error("Error fetching today's logins:", error);
      }
    };

    fetchTodaysLogins();
  }, []);


  useEffect(() => {
    // Fetch Departments
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/departments`);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);


  const filterFirstLoginPerUserPerDate = (attendanceData) => {
    const sortedData = attendanceData.sort((a, b) => new Date(a.record_time) - new Date(b.record_time));
  
    const firstLoginMap = new Map();

    sortedData.forEach((attendance) => {
      const recordDate = new Date(attendance.record_time).toLocaleDateString();
      const userId = attendance.user_info.user_id;
      if (!firstLoginMap.has(`${recordDate}-${userId}`)) {
        firstLoginMap.set(`${recordDate}-${userId}`, attendance);
      }
    });
  
    const filteredData = Array.from(firstLoginMap.values());
  
    return filteredData;
  };
  

  useEffect(() => {

    const user_info = JSON.parse(localStorage.getItem('user_info'));

    const fetchData = async () => {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/todayfirstAttendance`;

        if (user_info.role === 'admin') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/todayfirstAttendance`;
        } else if (user_info.role === 'hr') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/todayfirstAttendance/${user_info.branch_id}`;
        }
        const response = await axios.get(
          apiUrl
        );
        const attendanceData = response.data;

        let filteredByDepartment = attendanceData;

        if (selectedDepartment !== 'All') {
          filteredByDepartment = attendanceData.filter((attendance) => 
            attendance.user_info.department_id === parseInt(selectedDepartment)
          );
        }

        console.log(filteredByDepartment,"filteredByDepartment")
        const filteredData = filterFirstLoginPerUserPerDate(filteredByDepartment);
        const sortedFilteredData = filteredData.sort((a, b) => new Date(b.record_time) - new Date(a.record_time));
        setEmployeesData(sortedFilteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [selectedDepartment]);
  
  

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const filteredEmployees = employeesData.filter((employee) =>
    employee.user_info?.name.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/departments`);
        setDepartments(response.data);

        const startTimes = {};
        response.data.forEach(dept => {
          startTimes[dept.id] = new Date(dept.starting_time);
        });
        setDepartmentStartTimes(startTimes);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  const getAttendanceStatus = (recordTime, departmentId) => {
    const departmentStartTime = departmentStartTimes[departmentId];
    if (!departmentStartTime) return "Unknown";
  
    // Convert department start time to a Date object
    const deptStartTime = new Date(departmentStartTime);
  
    // Extract hours and minutes
    const deptStartHours = deptStartTime.getHours();
    const deptStartMinutes = deptStartTime.getMinutes();
  
    // Convert record time to a Date object
    const recordTimeDate = new Date(recordTime);
    const recordHours = recordTimeDate.getHours();
    const recordMinutes = recordTimeDate.getMinutes();
  
    // Calculate the total minutes past department start time
    const totalMinutesLate = (recordHours - deptStartHours) * 60 + (recordMinutes - deptStartMinutes);
  
    if (totalMinutesLate > 60) {
      return "orange"; // Very late
    } else if (totalMinutesLate > 30) {
      return "#ADD8E6"; // Late
    } else {
      return "#90EE90"; // On Time
    }
  };

  
  const getAttendanceStatusText = (recordTime, departmentId) => {
    const departmentStartTime = departmentStartTimes[departmentId];
    if (!departmentStartTime) return "Unknown";
  
    // Similar conversion as above
    const deptStartTime = new Date(departmentStartTime);
    const deptStartHours = deptStartTime.getHours();
    const deptStartMinutes = deptStartTime.getMinutes();
  
    const recordTimeDate = new Date(recordTime);
    const recordHours = recordTimeDate.getHours();
    const recordMinutes = recordTimeDate.getMinutes();
  
    const totalMinutesLate = (recordHours - deptStartHours) * 60 + (recordMinutes - deptStartMinutes);
    console.log(totalMinutesLate ,"TotalMinutesLate")
    if (totalMinutesLate > 60) {
      return "Very late";
    } else if (totalMinutesLate > 30) {
      return "Late";
    } else {
      return "On Time";
    }
  };
  

  return (
    <>
        <Typography
          mt={3}
          p={3}
          sx={{ fontWeight: 600, width: "70%" }}
          variant="h5"
        >
         Today Attendance
        </Typography>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
      
      {/* Department Selector */}
        <Box flex={1} ml={1}>
        <FormControl 
        fullWidth 
        sx={{ 
          m: 1, 
          width: "70%", 
          backgroundColor: "white", // Adjust the color as per your theme
          borderRadius: 1, 
          boxShadow: 1,
          '&:hover': {
            boxShadow: 3,
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'primary.main',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
      >
        <InputLabel htmlFor="department-select">Departments</InputLabel>
        <Select
          fullWidth
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          id="department-select"
          label="Departments"
          inputProps={{
            classes: {
              icon: 'MuiSelect-icon', // Optional: Adjust icon styling if needed
            },
          }}
        >
          <MenuItem value="All">All</MenuItem>
          {departments.map((department) => (
            <MenuItem key={department.id} value={department.id}>
              {department.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
        </Box>
      </Box>

      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box
            mx={2}
            sx={{
              cursor: "pointer",
              transition: "color 0.2s, font-weight 0.2s",
              position: "relative",
              fontWeight: activeTab === "LoggedIn" ? 600 : 500,
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "5px" /* Increase thickness */,
                borderRadius: "20%",
                backgroundColor: "blue" /* Change color */,
                transform: "scaleX(0)",
                transformOrigin: "right",
                transition: "transform 0.2s",
              },
              "&:hover": {
                color: "black",
                fontWeight: 600 /* Make the font bold */,
                "&::before": {
                  transform: "scaleX(1)",
                  transformOrigin: "left",
                },
              },
            }}
            onClick={() => setActiveTab("LoggedIn")}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 500, marginBottom: ".2rem" }}
            >
              Logged In ({todaysLogins.totalLogins})
            </Typography>
          </Box>
          <Box
            mx={2}
            sx={{
              cursor: "pointer",
              transition: "color 0.2s, font-weight 0.2s",
              position: "relative",
              fontWeight: activeTab === "OnTime" ? 600 : 500,
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px",
                borderRadius: "20%",
                backgroundColor: "blue" /* Change color */,
                transform: "scaleX(0)",
                transformOrigin: "right",
                transition: "transform 0.2s",
              },
              "&:hover": {
                color: "black",
                fontWeight: 600 /* Make the font bold */,
                "&::before": {
                  transform: "scaleX(1)",
                  transformOrigin: "left",
                },
              },
            }}
            onClick={() => setActiveTab("OnTime")}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            On Time ({todaysLogins.onTime})
            </Typography>
          </Box>
          <Box
            mx={2}
            sx={{
              cursor: "pointer",
              transition: "color 0.2s, font-weight 0.2s",
              position: "relative",
              fontWeight: activeTab === "Late" ? 600 : 500,
              "&::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "4px",
                borderRadius: "20%",
                backgroundColor: "blue" /* Change color */,
                transform: "scaleX(0)",
                transformOrigin: "right",
                transition: "transform 0.2s",
              },
              "&:hover": {
                color: "black",
                fontWeight: 600 /* Make the font bold */,
                "&::before": {
                  transform: "scaleX(1)",
                  transformOrigin: "left",
                },
              },
            }}
            onClick={() => setActiveTab("Late")}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Late ({todaysLogins.late})
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Search Bar */}
      <Box mt={2}>
        <Input
          placeholder="Search employee"
          fullWidth
          value={searchKeyword} 
          onChange={handleSearchChange}
        />
      </Box>
      {loading && <Box sx={{ display: 'flex',justifyContent:"center",alignItems:"center",height:"200px" , width:"100%" }}>
      <CircularProgress />
    </Box>}
      {!loading && ( 
        <>
        <Box
      sx={{
        maxHeight: 900, 
        overflowY: 'auto', 
        width: "100%",
        padding: "10px",
        boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5', // Background of the scrollbar track
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
          backgroundColor: '#555', // Scroll thumb color
          '&:hover': {
            backgroundColor: '#666', // Hover effect for the thumb
          }
        }
      }}
    >
      {filteredEmployees.map((employee) => (
        <Box
        key={employee.userSn}
        onClick={() => handleEmployeeClick(employee.user_info.id)}
        display="flex"
        alignItems="center"
        borderBottom="1px solid #ccc"
        justifyContent="space-between"
        padding="0px 10px"
        py={1}
        sx={{
        cursor:"pointer",
          transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out", 
          "&:hover": {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
            transform: "translateY(-5px)", 
          }
        }}
        >
          <Box display="flex" alignItems="center">
            <Avatar sx={{ mr: 2 }}>
              {/* Use the image source from employee.userInfo if available */}
              {employee.userInfo && employee.userInfo.image ? (
                <img
                  src={employee.userInfo.image}
                  alt={employee.userInfo.name}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                // Use a default image or initials if image is not available
                employee.name && employee.name.charAt(0)
              )}
            </Avatar>
            <Box>
              <Typography variant="subtitle1" fontWeight={600}>
                {employee.user_info.name}
              </Typography>
              <Typography variant="body2">
               {employee.user_info.position}
              </Typography>
              <Typography variant="body2">
              LogIn Date: {new Date(employee.record_time).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">
              LogIn Time: {new Date(employee.record_time).toLocaleTimeString()}
            </Typography>
            
             
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              height: "10px",
              width: "10px",
              background: getAttendanceStatus(employee.record_time, employee.user_info.department_id),
              borderRadius: "50%",
              margin: "0px 10px",
            }}
          ></Box>
          {getAttendanceStatusText(employee.record_time, employee.user_info.department_id)}
        </Box>
        
            {employee.status}
          </Box>
          
        </Box>
        ))}
        </Box>
              <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4
          style={{ alignSelf: "start", marginLeft: "50px", marginTop: "20px" }}
        >
          Calendar
        </h4>
        <Box sx={{ width:"100%", }}>
          <DateCalendarServerRequest />
        </Box>
      </Box>
        </>
      )}
    
    </>
  );
};

export default DashboardDetailPortion;
