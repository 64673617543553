import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, Button, Pagination } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { theme } from "../../Theme";
import AttendanceComponent from "./Heading";

const PaginationCard = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of cards to show per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const userInfo = JSON.parse(localStorage.getItem("user_info")); // Get user info from local storage
      let userId;
      if (userInfo.role === 'admin' || userInfo.role === 'hr') {
        userId = localStorage.getItem("selectedEmployeeId");
      } else {
        userId = localStorage.getItem("employeeid");
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dbAttendance/userAttendancedate/${userId}`);
        // Sort the data with newest first
        const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setAttendanceData(sortedData);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchAttendanceData();
  }, []);

  const handleRoute = () => {
    navigate("/employee-attendance");
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatTime = (isoString) => {
    return new Date(isoString).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const checkAttendanceStatus = (checkInTime) => {
    const checkInHour = new Date(checkInTime).getHours();
    return checkInHour < 11 ? "On Time" : "Late";
  };

  const renderCards = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentCards = attendanceData.slice(startIndex, endIndex);

    return currentCards.map((item, index) => (
      <Card
        key={index}
        sx={{
          margin: "1rem",
          width: { xs: "100%", sm: "48%", md: "30%" },
          textAlign: "center",
          borderRadius: "2rem",
          background: "linear-gradient(0.7turn, #55d7b3, #203341)",
        }}
      >
        <CardContent>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            color={theme.palette.secondary.main}
          >
            <Box display={"flex"} alignItems={"center"}>
              <AccessTimeIcon sx={{ marginRight: "5px" }} />
              <Typography>{new Date(item.date).toLocaleDateString()}</Typography>
            </Box>

            <Typography
              backgroundColor={checkAttendanceStatus(item.checkInTime) === "On Time" ? "rgba(144, 238, 144, 0.5)" : "rgba(255, 99, 71, 0.5)"}
              sx={{
                padding: ".3rem",
                borderRadius: "2rem",
              }}
            >
              {checkAttendanceStatus(item.checkInTime)}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            mt={3}
            color={theme.palette.secondary.main}
          >
            <Box>
              <Typography variant="span" sx={{ color: "#E5E4E2" }}>
                Check-In Time
              </Typography>
              <Typography variant="h6">{formatTime(item.checkInTime)}</Typography>
            </Box>
            <Box>
              <Typography variant="span" sx={{ color: "#E5E4E2" }}>
                Check-Out Time
              </Typography>
              <Typography variant="h6">{formatTime(item.checkOutTime)}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  const totalPages = Math.ceil(attendanceData.length / itemsPerPage);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "1.5rem",
      }}
    >
      <AttendanceComponent />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {renderCards()}
      </Box>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      {currentPage < totalPages && (
        <Button
          variant="outlined"
          onClick={handleRoute}
          sx={{ borderRadius: "2rem", marginTop: "1rem" }}
        >
          View More Details
        </Button>
      )}
    </Box>
  );
};

export default PaginationCard;
