import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
export const NavListConstants = [
  { id: 1, title: "Dashboard", icon: <HomeIcon />, to: "/" },
  {
    id: 2,
    title: "Employees",
    icon: <AccountCircleIcon />,
    to: "#",
  },
  {
    id: 3,
    title: "Leave Management",
    icon: <EventNoteIcon />,
    to: "#",
  },
  { id: 4, title: "Holiday", icon: <LocalCafeIcon />, to: "#" },
  { id: 5, title: "Attendance", icon: <NoteAltIcon />, to: "#" },
  { id: 6, title: "Settings", icon: <SettingsIcon />, to: "/settings" },
  { id: 7, title: "Log out", icon: <LogoutIcon />, to: "/log-out" },
];

export const EmployeeNavListConstants = [
  { id: 1, title: "All Employees", to: "/all-employee" },
  { id: 2, title: "Add Employee", to: "/add-employee" },
  // { id: 3, title: "Edit Employee", to: "/edit-employee" },
];
export const HrNavList = [
  { id: 1, title: "All managers", to: "/all-hr" },
  { id: 2, title: "Add manager", to: "/add-hr" },
  // { id: 3, title: "Edit manager", to: "/edit-hr" },
  // { id: 4, title: "Manager Details", to: "/detail-hr" },
  // { id: 5, title: "Leave Report", to: "/leaves" },
];
export const CompanyList = [
  { id: 1, title: "All Branches", to: "/all-companies" },
  { id: 2, title: "Add Branch", to: "/add-company" },
  // { id: 3, title: "Edit Branch", to: "/edit-company" },
];
export const DepartmentList = [
  { id: 1, title: "All Departments", to: "/all-departments" },
  { id: 2, title: "Add Department", to: "/add-department" },
  // { id: 3, title: "Edit Department", to: "/edit-department" },
];
export const LeaveManagementConstants = [
  { id: 1, title: "All Leaves", to: "/all-leaves" },
  { id: 2, title: "Leave Types", to: "/leaves-type" },
  { id: 3, title: "Leave Balance", to: "/leave-balance" },
];
export const LeaveManagementHRConstants = [
  { id: 1, title: "All Leaves", to: "/all-leaves" },

  { id: 2, title: "Leave Balance", to: "/leave-balance" },
  { id: 3, title: "Leave Report", to: "/leaves" },
];
export const AttendanceConstants = [
  { id: 1, title: "Todays Attendance", to: "/attendance-report" },
  // { id: 2, title: "Employees Attendance", to: "/employee-attendance" },
  { id: 3, title: "Attendance Sheet", to: "/attendance-sheet" },
];

export const HolidaysConstants = [
  { id: 1, title: "All Holidays", to: "/all-holidays" },
  { id: 2, title: "Add Holidays", to: "/add-holiday" },
];
export const EmployeePageConstants = [
  { id: 1, title: "My Profile", to: "/details", icon: <AccountCircleIcon /> },
  { id: 2, title: "Attendance", to: "/attendance", icon: <NoteAltIcon /> },
  { id: 3, title: "Leave Report", to: "/leaves", icon: <EventNoteIcon /> },
];
