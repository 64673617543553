import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ProfileTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: 3,
        width: "100%",

        position: "relative",
        borderRadius: ".5rem",
        marginLeft: "2rem",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        aria-label="Profile tabs"
        variant="fullWidth"
      >
        <Tab label="About Me" />
        <Tab label="Settings" />
      </Tabs>

      <CardContent>
        {value === 0 && (
          <div className="tab-content">
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              About
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "600" }}>Full Name</Typography>
                <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                  Faisal Haroon
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "600" }}>Mobile</Typography>
                <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                  0305-4052725
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "600" }}>Email</Typography>
                <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                  FaisalHaroon500@gmail.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "600" }}>Location</Typography>
                <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                  Pakistan
                </Typography>
              </Box>
            </Box>
            <Box mt={8}>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                suscipit necessitatibus accusamus natus dignissimos labore
                dolorum officiis accusantium laudantium, maxime, aspernatur
                temporibus amet? Blanditiis nulla numquam accusamus harum,
                fugiat reiciendis dolorum accusantium quod, ea laudantium
                consequatur quis, repellendus error! Officia, blanditiis, ab
                ipsa amet quos repellat cupiditate quod labore, tempora odio
                accusantium enim beatae et? Repellendus maxime perferendis
                molestiae voluptas.
              </Typography>
            </Box>
            <Box mt={8}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Education
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
            </Box>
            <Box mt={8}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Experience
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>{" "}
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>{" "}
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>{" "}
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>{" "}
              <Typography sx={{ fontWeight: "light", fontSize: ".7rem" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officia, adipisci.
              </Typography>
            </Box>
          </div>
        )}

        {value === 1 && (
          <div className="tab-content">
            <Typography variant="body1">Settings Tab Content</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileTabs;
