import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import image from "../../newProfile.jpg";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";

const NewProfile = () => {
  const navigate = useNavigate();
  const directPage = () => {
    navigate("/add-employee");
  };
  return (
    <Box
      sx={{
        height: "380px",
        width: "100%",
        borderRadius: "1rem",
        overflow: "hidden",
        boxShadow: "0 10px 10px #0000004d",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          width: "100%",
          heigth: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={image}
          alt="Demo Image"
          style={{
            width: "100%",
            paddingBottom: "1rem",
          }}
        />
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            sx={{
              fontWeight: "bold",
              marginRight: "1rem",
            }}
          >
            Add new employee
          </Typography>
          <IconButton onClick={() => directPage()}>
            <AddCircleOutlineIcon
              fontSize="large"
              sx={{ color: "blue", cursor: "pointer" }}
            />
          </IconButton>
        </Box>
      </div>
    </Box>
  );
};

export default NewProfile;
