import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import { theme } from "../../Theme";
import axios from "axios";
import AddLeaveRequestDialog from "./CreateLeaveTypeForAdmin";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import EditLeaveTypeDialog from "./CreateLeaveTypeForAdmin2";
import { toast } from "react-toastify";

const LeaveTypePage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isAddLeaveDialogOpen, setAddLeaveDialogOpen] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false); // State for the edit modal
  const [selectedLeaveType, setSelectedLeaveType] = useState(null); // State to track the leave type being edited
  const token = localStorage.getItem("token");

  const fetchLeaveTypes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/leaveType/`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      console.log("Fetched leave types data:", response.data);
      setLeaveTypes(response.data);
      setLeaveRequests(response.data);
    } catch (error) {
      console.error("Error fetching leave types:", error);
    }
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleDeleteLeaveType = async (leaveTypeId) => {
    console.log("Deleting leave type with ID:", leaveTypeId);

    if (!leaveTypeId) {
      console.error("Invalid leave type ID");
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/leaveType/${leaveTypeId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      console.log("Leave type deleted successfully!");
      fetchLeaveTypes();
      toast.success("Leave type deleted successfully!");
    } catch (error) {
      console.error("Error deleting leave type:", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditLeaveType = (leaveType) => {
    setSelectedLeaveType(leaveType);
    setEditDialogOpen(true);
  };

  const updateLeaveRequests = async (newLeaveRequest) => {
    try {
      await fetchLeaveTypes();
      setLeaveRequests((prevLeaveRequests) => [
        ...prevLeaveRequests,
        newLeaveRequest,
      ]);
    } catch (error) {
      console.error("Error updating leave requests:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const openAddLeaveDialog = () => {
    setAddLeaveDialogOpen(true);
  };

  const closeAddLeaveDialog = () => {
    setAddLeaveDialogOpen(false);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedLeaveType(null);
  };

  const filteredLeaveTypes = leaveRequests.filter(
    (leaveType) =>
      (leaveType.leave_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        "") &&
      (leaveType.leave_type
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        "") &&
      (leaveType.leave_units
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        "") &&
      (leaveType.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        "")
  );
  const exportToExcel = () => {
    const formattedData = leaveRequests.map((leaveType) => ({
      "Leave Name": leaveType.leave_name,
      "Leave Type": leaveType.leave_type,
      "Leave Units": leaveType.leave_units,
      Status: leaveType.status,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LeaveRequests");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "LeaveRequests.xlsx");
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography variant="h5">Leave Types</Typography>
        <Paper sx={{ marginTop: "2rem" }}>
          <AppBar position="static" color="primary">
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: { xs: "end", sm: "space-between" },
              }}
            >
              <Typography
                sx={{
                  marginRight: "2rem",
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
                variant="h6"
              >
                Leave Types
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />
                <IconButton color="inherit" onClick={openAddLeaveDialog}>
                  <Tooltip title="Add new Leave" arrow>
                    <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
                  </Tooltip>
                </IconButton>
                <IconButton color="inherit" onClick={exportToExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} />
                  </Tooltip>{" "}
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Leave Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Leave Type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Leave Units</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLeaveTypes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((leaveType) => (
                    <TableRow key={leaveType.id}>
                      <TableCell>{leaveType.leave_name}</TableCell>
                      <TableCell>{leaveType.leave_type}</TableCell>
                      <TableCell>{leaveType.leave_units}</TableCell>
                      <TableCell
                        sx={{
                          color:
                            leaveType.status === "Active"
                              ? "green"
                              : leaveType.status === "Accepted"
                              ? "blue"
                              : "red",
                        }}
                      >
                        {leaveType.status}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditLeaveType(leaveType)}
                        >
                          <EditIcon
                            sx={{ color: theme.palette.editIcon.main }}
                          />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteLeaveType(leaveType.id)}
                        >
                          <DeleteOutlineIcon
                            sx={{ color: theme.palette.deleteIcon.main }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              rowsPerPage={rowsPerPage}
              page={page}
              count={leaveRequests.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            />
          </TableContainer>
        </Paper>
      </Box>
      <AddLeaveRequestDialog
        open={isAddLeaveDialogOpen}
        onClose={closeAddLeaveDialog}
        updateLeaveRequests={updateLeaveRequests}
        fetchLeaveTypes={fetchLeaveTypes}
      />
      <EditLeaveTypeDialog
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        updateLeaveRequests={updateLeaveRequests}
        leaveTypeToEdit={selectedLeaveType}
      />
    </Grid>
  );
};

export default LeaveTypePage;
