import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Graph from "./Graph";
import Backlog from "./BackLog";
import NewProfile from "./NewProfile";
import VerticalCardList from "./DashbboardCards";
import DashboardDetailPortion from "./DashboardAllProfile";
import { theme } from "../../Theme";
import LeaveRequestDashboard from "../LeaveManagement/LeaveRequestDashboard";
import GraphForThisWeekAttendance from "./GraphForThisWeekAttendance";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Importing an icon for time
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

const DashBoard = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getGreetingTime = (currentTime) => {
    const split_afternoon = 12; // 24hr time to split the afternoon
    const split_evening = 17; // 24hr time to split the evening
    const currentHour = currentTime.getHours();

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      return "Good Afternoon";
    } else if (currentHour >= split_evening) {
      return "Good Evening";
    }
    return "Good Morning";
  };

  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const userName = userInfo
    ? `(${capitalizeFirstLetter(userInfo.role)}) ${
        userInfo.username.split(" ")[0]
      }`
    : "";
    const greeting = getGreetingTime(time);
  const formattedTime = time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            width: { md: "70%", xs: "100%" },
            display: "flex",
            flexDirection: { xl: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              width: { xl: "75%" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              justifyContent="center"
              p="7px"
              gap={5}
              sx={{
                marginTop: "5rem",
                width:"max-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "10px",
                  padding: "15px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  margin: "10px",
                }}
              >
                <EmojiPeopleIcon
                  sx={{ color: "#1976d2", marginRight: "5px" }}
                />{" "}
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#333" }}
                >
                  {greeting}, {userName}
                </Typography>
                <AccessTimeIcon
                  sx={{
                    color: "#1976d2",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />{" "}
                <Typography variant="h6" sx={{ color: "#555" }}>
                  {formattedTime}
                </Typography>
              </Box>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ padding: "0px 15px", marginBottom: "20px" }}>
                <Graph />
              </Box>
              <Box sx={{ padding: "0px 15px" }}>
                <GraphForThisWeekAttendance />
              </Box>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginInline: "1rem",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                    <Backlog />
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "100%", md: "38%" },
                      marginBottom: { xs: "20px", md: "0px" },
                    }}
                  >
                    <NewProfile />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    padding: "0px 20px",
                    marginBottom: "40px",
                  }}
                >
                  <LeaveRequestDashboard />
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xl: "25%", xs: "100%" },
              padding: "1rem",
              marginTop: { xl: "8.6rem", xs: "0rem" },
              display: "flex",
              justifyContent: "center",
              flexDirection: { xl: "row", xs: "column" },
            }}
          >
            <VerticalCardList />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "30%" },
            padding: "1rem",
            backgroundColor: theme.palette.secondary.main,
            marginTop: "4rem",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <DashboardDetailPortion />
        </Box>
      </Box>
    </>
  );
};

export default DashBoard;
