import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import axios from "axios";
const VerticalCardList = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [departmentNames, setDepartmentNames] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const attendanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/dbAttendance/dpsummary/`);
        setAttendanceData(attendanceResponse.data);
        const departmentResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/departments/`);
        setDepartmentNames(departmentResponse.data.map((dept) => ({ id: dept.id, name: dept.name })));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const getDepartmentName = (departmentId) => {
    const department = departmentNames.find((dept) => dept.id === departmentId);
    return department ? department.name : "";
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography mb={1} variant="h6" sx={{ color: "gray", fontWeight: 700 }}>
        All departments
      </Typography>
      {attendanceData?.map((dept, index) => (
        <Box
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          key={index}
          width="100%"
          borderRadius="16px"
          height="170px"
          overflow="auto"
          marginBottom={"1.5rem"}
        >
          <CardContent sx={{ background: "white" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={"space-between"}
            >
              <Box>
                <Typography variant="span" sx={{ color: "gray" }}>
                  Total
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "black", fontWeight: "600" }}
                >
                  {dept.totalEmployees}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="span"
                  sx={{ color: "gray", fontWeight: 400 }}
                >
                  On-time:
                  <span style={{ color: "black", fontWeight: 600 }}>
                    {dept.onTime}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "gray", fontWeight: 400 }}
                >
                  Late:{" "}
                  <span style={{ color: "black", fontWeight: 600 }}>
                    {dept.late}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle2 "
                  sx={{ color: "gray", fontWeight: 400 }}
                >
                  Leave:
                  <span style={{ color: "black", fontWeight: 600 }}>
                    {dept.onLeave}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                marginTop: "1rem",
                fontWeight: 700,
                borderTop: "1px solid #ccc",
                paddingTop: "0.5rem",
                color: "gray",
              }}
            >
              {getDepartmentName(dept.departmentId)}
            </Typography>
          </CardContent>
        </Box>
      ))}
    </div>
  );
};
export default VerticalCardList;