import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios"; // Import Axios
import { toast } from "react-toastify";

const AddLeaveRequestDialog = ({
  open,
  onClose,
  createLeave,
  newId,
  updateLeaveRequests,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [leaveTypes, setLeaveTypes] = useState([]); // State to hold leave types data
  const [selectedLeaveType, setSelectedLeaveType] = useState(""); // State to store selected leave type
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log("AddLeaveRequestDialog is open:", open);
  }, [open]);
  // Fetch leave types data when the component mounts
  useEffect(() => {
    async function fetchLeaveTypes() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leaveType/`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log("Fetched leave types data:", response.data);
        setLeaveTypes(response.data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    }
    fetchLeaveTypes();
  }, []);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  // const handleStatusChange = (event) => {
  //   setSelectedStatus(event.target.value);
  // };

  const handleLeaveTypeChange = (event) => {
    setSelectedLeaveType(event.target.value);
  };

  const handleAddLeave = async () => {
    const name = document.getElementById("name").value;
    const numberOfDays = document.getElementById("numberOfDays").value;
    const reason = document.getElementById("reason").value;
    const department = JSON.parse(localStorage.getItem("user_info")).department_id;
    const employeeId = newId;

    if (!name || !selectedLeaveType || !numberOfDays || !reason) {
      toast.error("Please fill in all required fields.");
        return;
    }

    if (!selectedStartDate || !selectedEndDate) {
        alert("Please select valid start and end dates.");
        return;
    }

    const fromDate = selectedStartDate;
    const toDate = selectedEndDate;
    const dateDiffInMilliseconds = Math.abs(toDate - fromDate);
    const numberOfDaysSelected = Math.ceil(
        dateDiffInMilliseconds / (1000 * 60 * 60 * 24)
    );

    const currentDate = new Date().toISOString().split("T")[0];
    const leaveData = {
        user_id: employeeId,
        department_id: department,
        name,
        apply_date: currentDate,
        from_date: fromDate,
        to_date: toDate,
        half_day: false,
        type: selectedLeaveType,
        status: "pending",
        reason,
        number_of_days: numberOfDays,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/leave/`,
        leaveData
      );

      if (response.status === 201) {
        toast.success("Leave added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateLeaveRequests(response.data);
        onClose();
      } else {
        toast.error("Failed to add leave", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error adding leave:", error);
      toast.error("An error occurred while adding leave. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
};



  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Add Leave Request
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={6}>
            <TextField id="name" label="Name" fullWidth required />
          </Grid>
          <Grid item xs={6}>
  <TextField
    id="leaveType" 
    label="Leave Type"
    fullWidth
    select
    required
    value={selectedLeaveType}
    onChange={handleLeaveTypeChange}
  >
    {leaveTypes?.map((leaveTypeOption) => (
      <MenuItem
        key={leaveTypeOption.id}
        value={leaveTypeOption.leave_type}
      >
        {leaveTypeOption.leave_type}
      </MenuItem>
    ))}
  </TextField>
</Grid>

          <Grid item xs={6}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleStartDateChange(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleEndDateChange(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="numberOfDays"
              label="Number of Days"
              type="number"
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="reason"
              label="Reason"
              fullWidth
              multiline
              rows={4}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Button
                onClick={handleAddLeave}
                color="primary"
                variant="contained"
              >
                Add Leave
              </Button>
              <Button
                onClick={onClose}
                color="error"
                variant="contained"
                sx={{ marginLeft: "1rem" }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddLeaveRequestDialog;
