import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Card,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { theme } from "../../Theme";
import axios from "axios";
import EditHolidayDialog from "./ModalForHolidayEdit";
import { toast } from "react-toastify";

const HolidaysTable = () => {
  const [holidays, setHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentHoliday, setCurrentHoliday] = useState(null);
  const [update, setUpdate] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/holiday/`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setHolidays(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [update]);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteClick = (employee) => {
    setCurrentHoliday(employee);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    const id = currentHoliday;
    setHolidays((prevHolidays) =>
      prevHolidays.filter((holiday) => holiday.id !== id)
    );
    setIsDeleteModalOpen(false);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/holiday/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Holiday deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("Holiday deleted successfully");
        } else {
          console.error("Failed to delete holiday");
        }
      })
      .catch((error) => {
        toast.error("Error deleting holiday", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("Error deleting holiday:", error);
      });
  };

  const handleEditClick = (holiday) => {
    setSelectedHoliday(holiday);
    setEditDialogOpen(true);
    console.log("Holiday", holiday);
  };

  const closeEditDialog = () => {
    setSelectedHoliday(null);
    setEditDialogOpen(false);
  };

  const updateHolidays = (editedHoliday) => {
    setHolidays((prevHolidays) =>
      prevHolidays.map((holiday) =>
        holiday.id === editedHoliday.id ? editedHoliday : holiday
      )
    );
    closeEditDialog();
    setUpdate(editedHoliday);
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        {" "}
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5">All Holidays</Typography>
          <Card
            elevation={3}
            sx={{
              padding: "1rem",
              width: "100%",
              margin: "0 auto",
              marginTop: "2rem",
            }}
          >
            <Tooltip sx={{ backgroundColor: "red" }}>
              <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
                All Holidays
              </Typography>
            </Tooltip>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ width: "98%", backgroundColor: "#f9f9f9" }}>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Location</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Shift</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {holidays.map((holiday) => (
                    <TableRow key={holiday.id}>
                      <TableCell>{holiday.holidays_name}</TableCell>
                      <TableCell>{new Date(holiday.holidays_date).toISOString().split('T')[0]}</TableCell>
                      <TableCell>{holiday.holidays_location}</TableCell>
                      <TableCell>{holiday.shift}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton onClick={() => handleEditClick(holiday)}>
                          <EditIcon
                            sx={{ color: theme.palette.editIcon.main }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(holiday.id)}
                        >
                          <DeleteOutlineIcon
                            sx={{ color: theme.palette.deleteIcon.main }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
        <EditHolidayDialog
          open={isEditDialogOpen}
          onClose={closeEditDialog}
          holiday={selectedHoliday}
          updateHoliday={updateHolidays}
        />
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HolidaysTable;
