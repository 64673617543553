import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";



function fakeFetch({ signal, setHighlightedDays }) {
  return fetch(`${process.env.REACT_APP_API_URL}/api/holiday/`, { signal: signal })
    .then((response) => response.json())
    .then((data) => {
      if (!Array.isArray(data)) {
        console.error("Expected array but received:", data);
        return { daysToHighlight: [], data: [] }; // Return empty data if response is not an array
      }
      
      const daysToHighlight = data.map((holiday) =>
        dayjs(holiday.holidays_date).format("YYYY-MM-DD")
      );

      setHighlightedDays(daysToHighlight);
      return { daysToHighlight, data };
    })
    .catch((error) => {
      console.error("Error fetching holidays:", error);
      throw error;
    });
}

const initialValue = dayjs().startOf("month");

function ServerDay(props) {
  const { data, highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const formattedDay = dayjs(day).format("YYYY-MM-DD");
  const isCurrentDate = dayjs().isSame(day, "day"); // Check if 'day' is the current date

  const holiday = data?.find(
    (holiday) => formattedDay === dayjs(holiday.holidays_date).format("YYYY-MM-DD")
  );

  const isSelected = !outsideCurrentMonth && highlightedDays.includes(formattedDay);

  let sxStyles = {
    fontSize: "16px",
    margin: "3px",
    backgroundColor: isSelected ? "#ffcc80" : undefined, // Existing logic for selected days
    // Apply black background for the current date
    ...(isCurrentDate && {
      backgroundColor: "#000000", // Black color for the current date
      borderRadius: "50%",
      color:"white" // Circular shape
    }),
  };

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "🔴" : undefined}
      title={holiday ? holiday.holidays_name : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={sxStyles}
      />
    </Badge>
  );
}



export default function DateCalendarServerRequest() {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const [data, setData] = React.useState([]);

  const fetchHighlightedDays = () => {
    const controller = new AbortController();
    fakeFetch({ signal: controller.signal, setHighlightedDays })
      .then(({ daysToHighlight, data }) => {
        setHighlightedDays(daysToHighlight);
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays();
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <div
      style={{
        width: "90%",
        margin: "20px auto",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 5px 20px 0px",
        backgroundColor:"white",
        borderRadius: "1rem",
        padding:"10px 0px"
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: (props) => <ServerDay {...props} data={data} />,
          }}
          slotProps={{
            day: {
              highlightedDays,
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
