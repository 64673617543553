import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  styled,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../Assets/images/authPages/1 (1).png"
import axios from "axios";
import { setSelectedResponse } from "../../redux/role/roleSlice";
import { toast } from "react-toastify";

// Custom theme overrides and responsive styles can be done here
const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  borderRadius: "16px",
  overflow: "hidden",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
}));

// LeftContainer for the Welcome Back section with matching colors and styles
const LeftContainer = styled(Grid)(({ theme }) => ({
  color: "white", // Adjust text color to match the design
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  background: "linear-gradient(0.7turn, rgba(85, 215, 179, 1), rgba(32, 51, 65, 1))",
backdropFilter: "blur(5.5px)"
}));

const RightContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(10, 5),
  display: "flex",
  flexDirection: "column",
  backdropFilter: "blur(15px)",
  justifyContent: "center",
  backgroundColor: " rgba(255, 255, 255, 0.45)",
}));

const StyledTextField = styled(TextField)({
  margin: "0.5rem 0",
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "grey",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "grey",
    },
    padding: "18.5px 14px",
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
});


const SignInButton = styled(Button)({
  margin: "1rem 0",
  background: "linear-gradient(0.7turn, rgba(85, 215, 179, 1), rgba(32, 51, 65, 1))",
  fontSize:"20px",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#00897B",
  },
  backdropFilter: "blur(5.5px)"
});



const CenteredTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize:"20px"
}));

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const userData = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/login`,
        userData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const { user, token } = response.data;
        dispatch(setSelectedResponse(user));

        // Store the token in local storage
        localStorage.setItem("token", token);
        console.log("Token stored in local storage:", token);
        localStorage.setItem("user_info", JSON.stringify(user));
        console.log("User info stored in local storage:", user);
        localStorage.setItem("employeeid", response.data.user.id);
        console.log(
          "Employee id stored in local storage:",
          response.data.user.id
        );
        localStorage.setItem("managerid", response.data.user.id);
        navigate("/");
      } else {
        toast.error("Invalid credentials. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle other types of errors
      console.error("Error:", error.message);
      toast.error("Invalid credentials. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Check if the error is due to an HTTP response
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // Handle specific HTTP status codes if needed
        if (error.response.status === 401) {
          toast.error("Invalid credentials. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else if (error.request) {
        console.error("No Response:", error.request);
      } else {
        console.error("Request Error:", error.message);
      }
    }
  };

  return (
    <StyledPaper>
      <LeftContainer item xs={12} md={6}>
        <Typography variant="h4" component="h1" sx={{fontSize:"40px"}} gutterBottom>
          Welcome Back!
        </Typography>
        <CenteredTypography variant="body1">
          Please Log In With Your Personal Info
        </CenteredTypography>
      </LeftContainer>
      <RightContainer item xs={12} md={6}>
       
        <Box sx={{ display:"flex",justifyContent:"center" , alignItems:"center" , height:"60px"}}>
        <Avatar src={Logo} alt="clockify" sx={{ width: 240 ,height:240  }} />
        </Box>
        <Typography sx={{fontSize:"25px" , textAlign:"center"}} variant="body1" gutterBottom>
          Use your email for Login
        </Typography>
        <Box component="form" onSubmit={formSubmitHandler} noValidate>
          <StyledTextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
          />
          <StyledTextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            name="password"
          />
          <SignInButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Sign in
          </SignInButton>
        </Box>
      </RightContainer>
    </StyledPaper>
  );
};

export default LoginForm;
