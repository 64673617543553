import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Grid,
  Box,
} from "@mui/material";

import image from "./../../demo.jpg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import ColorTabs from "./Tabs";
import ProfileTabs from "./LargeTab";

const EmployeeCard = ({ employee }) => {
  return (
    <Grid
      container
      p={"1rem"}
      width={"100vw"}
      sx={{
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "98%", marginBottom: "2rem" }}>
        <Typography variant="h5">Profile</Typography>
      </Box>
      <Box sx={{ width: "31%" }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: 3,
            width: "100%",
            height: "25rem",
            position: "relative",
            borderRadius: ".5rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#1E4170",
              height: "40%",
              display: "flex",
              color: "white",

              justifyContent: "center",
              position: "relative",
            }}
          >
            <Box sx={{ textAlign: "center", marginTop: ".4rem" }}>
              <Typography sx={{}} variant="h6" gutterBottom>
                Faisal Haroon
              </Typography>
              <Typography sx={{ fontSize: ".8rem" }} gutterBottom>
                Mern Stack Intern
              </Typography>
            </Box>

            <Avatar
              src={image}
              sx={{
                width: 110,
                height: 110,
                bgcolor: "white",
                position: "absolute",
                bottom: "-45px",
                boxShadow: " 0 15px 25px #0000004d",
                // border: "5px solid white",
              }}
            ></Avatar>
          </div>
          <Divider />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",

              height: "60%",
              textAlign: "center",
            }}
          >
            <Box sx={{ marginTop: "3rem" }}>
              <Typography sx={{ fontSize: ".8rem" }}>
                630,Karim Block Allama Iqbal Town
              </Typography>
              <Typography sx={{ fontSize: ".8rem" }}>Lahore</Typography>
              <Typography
                sx={{
                  fontSize: ".8rem",
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LocalPhoneIcon sx={{ fontSize: "1rem" }} />
                0305-4052725
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <ColorTabs />
      </Box>
      <Box sx={{ width: "66%" }}>
        <ProfileTabs />
      </Box>
    </Grid>
  );
};

export default EmployeeCard;
