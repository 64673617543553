import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";

const AddLeaveRequestDialog = ({ open, onClose, updateLeaveRequests,fetchLeaveTypes }) => {
  const [leave_name, setleave_name] = useState("");
  const [leave_type, setleave_type] = useState("");
  const [leave_units, setleave_units] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const token = localStorage.getItem("token");

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCreateleave_type = async () => {
    if (!leave_name || !leave_type || !leave_units) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const leave_typeData = {
      leave_name,
      leave_type,
      leave_units,
      status: selectedStatus,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/leaveType/`,
        {
          method: "POST",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", 
          },
          body: JSON.stringify(leave_typeData),
        }
      );

      if (response.status === 201) {
        toast.success("Leave Type added successfully!");
        updateLeaveRequests(leave_typeData);
        onClose();
    
        // Reset form fields
        setleave_name("");
        setleave_type("");
        setleave_units("");
        setSelectedStatus("Pending");
    
        // Fetch updated leave types
        fetchLeaveTypes();
      } else {
        toast.error("Failed to add Leave Type. Please try again later.");
      }
    } catch (error) {
      console.error("Error adding Leave Type:", error);
      toast.error("An error occurred while adding Leave Type. Please try again later.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Add Leave Request
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={6}>
            <TextField
              id="leave_name"
              label="Leave Name"
              fullWidth
              required
              value={leave_name}
              onChange={(e) => setleave_name(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="leave_type"
              label="Leave Type"
              fullWidth
              required
              value={leave_type}
              onChange={(e) => setleave_type(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="leave_units"
              label="Leave Units"
              fullWidth
              required
              value={leave_units}
              onChange={(e) => setleave_units(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="status"
              label="Status"
              select
              fullWidth
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Accepted">Accepted</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Button
                onClick={handleCreateleave_type}
                color="primary"
                variant="contained"
              >
                Add Leave
              </Button>
              <Button
                onClick={onClose}
                color="error"
                variant="contained"
                sx={{ marginLeft: "1rem" }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddLeaveRequestDialog;
