import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { theme } from "../../Theme";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EmployeeForm = () => {
  const [branches, setBranches] = useState([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const token = localStorage.getItem("token");
  const [profilePicture, setProfilePicture] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [userBranch, setUserBranch] = useState("");

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    if (userInfo) {
      const { role } = userInfo;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    if (userInfo) {
      const { branch_id } = userInfo;
      setUserBranch(branch_id);
    }
  }, []);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    if (userInfo) {
      const { department_id } = userInfo;
      setUserDepartment(department_id);
    }
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/branch/`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setBranches(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/departments/`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setDepartments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfilePicture({ file, imageUrl });
    }
  };
  const handleRemoveImage = () => {
    setProfilePicture(null);
  };

  const handleBranchChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedBranch(selectedValue);
    formik.handleChange(event);
  };

  const handleDepartmentChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedDepartmentIds(selectedValues);
    formik.handleChange(event);
  };

  const handleCancel = () => {
    formik.resetForm();
    navigate("/all-employee");
  };

  const testOnSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      formData.append("employment_date", "2022-01-01");
      formData.append("employment_status", true);
      formData.append("phone_number", "+923123232423");
      formData.append("cnic", "123213213213123");
      
      const config = {
        headers: {
          Authorization: `${token}`,
          // "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/userinfo`,
        formData,
        config
      );
      if (response.status === 201) {
        handleCancel();
      }
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      branch_id: "",
      department_id: [],
      password: "",
      role: "",
      firstName: "",
      lastName: "",
      date_of_birth: "",
      gender: "",
      employmentDate: "",
      position: "",
      employmentStatus: true,
      address: "",
      phoneNumber: "",
      salary: "",
      CNIC: "",
      emergencyContactName: "",
      emergencyContactPhoneNumber: "",
      emergencyContactRelation: "",
    },
    onSubmit: async (values) => {
      console.log(values, "validation");
      try {
        // let profilePictureBase64 = null;
        // if (profilePicture) {
        //   const reader = new FileReader();
        //   reader.readAsDataURL(profilePicture);
        //   reader.onload = () => {
        //     profilePictureBase64 = reader.result;
        //   };
        //   await new Promise((resolve, reject) => {
        //     reader.onloadend = resolve;
        //     reader.onerror = reject;
        //   });
        // }

        const userData = {
          branch_id: selectedBranch,
          email: formik.values.email,
          username: values.username,
          password: values.password,
          role: "employee",
          firstName: values.firstName,
          lastName: values.lastName,
          date_of_birth: values.date_of_birth,
          gender: values.gender,
          employment_date: "2022-01-01",
          position: values.position,
          employment_status: true,
          address: values.address,
          phone_number: values.phoneNumber,
          salary: values.salary,
          cnic: values.CNIC,
          emergency_contact_name: "Emergency Contact",
          emergency_contact_phoneNumber: "987-654-3210",
          emergency_contact_relation: "Relative",
          profile_picture: profilePicture,
        };

        if (userRole === "hr") {
          userData.department_id = userDepartment;
        } else if (userRole === "admin") {
          userData.department_id = selectedDepartmentIds;
        }

        if (userRole === "hr") {
          userData.branch_id = userBranch;
        } else if (userRole === "admin") {
          userData.branch_id = selectedBranch;
        }
        const config = {
          headers: {
            Authorization: `${token}`,
            // "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/userinfo`,
          userData,
          config
        );

        if (response.status === 201) {
          formik.resetForm();
          toast.success("Employee added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Async Function Error:", error);
      }
    },
  });
  useEffect(() => {
    const filtered = departments.filter(
      (department) => department.branch_id === formik.values.branch_id
    );

    setFilteredDepartments(filtered);
  }, [formik.values.branch_id, departments]);

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
          Add Employee
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: " 0 15px 25px #0000004d",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Employee
          </Typography>
          <form
            onSubmit={testOnSubmit}
            method="post"
            enctype="multipart/form-data"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="User Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  label="Password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid> */}
              <Grid item xs={6} sm={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  required
                  sx={{
                    marginBottom: "0.5rem",
                    color: theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    label="Gender"
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    required
                    sx={{}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  required
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              {userRole === "admin" && (
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ marginBottom: "0.5rem", borderColor: "#1E4170" }}
                  >
                    <InputLabel id="branch-label">Branches</InputLabel>
                    <Select
                      labelId="branch-label"
                      id="branch"
                      name="branch_id"
                      label="Branches"
                      variant="outlined"
                      value={formik.values.branch_id}
                      onChange={handleBranchChange}
                      required
                      error={
                        formik.touched.branch_id &&
                        Boolean(formik.errors.branch_id)
                      }
                      helperText={
                        formik.touched.branch_id && formik.errors.branch_id
                      }
                      style={{ borderColor: "#1E4170" }}
                    >
                      <MenuItem value="" style={{ color: "#1E4170" }}>
                        Select Branch
                      </MenuItem>
                      {loading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : (
                        branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Position"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  required
                  error={
                    formik.touched.position && Boolean(formik.errors.position)
                  }
                  helperText={formik.touched.position && formik.errors.position}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              {userRole === "admin" && (
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    style={{ marginBottom: "0.5rem", borderColor: "#1E4170" }}
                  >
                    <InputLabel id="department-label">Departments</InputLabel>
                    <Select
                      labelId="department-label"
                      id="department"
                      name="department_id"
                      label="Departments"
                      variant="outlined"
                      value={formik.values.department_id}
                      onChange={handleDepartmentChange}
                      multiline
                      required
                      error={
                        formik.touched.department_id &&
                        Boolean(formik.errors.department_id)
                      }
                      helperText={
                        formik.touched.department_id &&
                        formik.errors.department_id
                      }
                      sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                    >
                      <MenuItem value="" style={{ color: "#1E4170" }}>
                        Select Department
                      </MenuItem>
                      {loading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : (
                        filteredDepartments.map((department) => (
                          <MenuItem key={department.id} value={department.id}>
                            {department.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date of Birth"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="date"
                  name="date_of_birth"
                  value={formik.values.date_of_birth}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Salary"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  name="salary"
                  value={formik.values.salary}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>{" "}
              <Grid item xs={6} sm={6}>
                <TextField
                  label="CNIC"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="CNIC"
                  value={formik.values.CNIC}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 2,
                }}
              >
                <label htmlFor="image-upload">
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    name="image"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    sx={{
                      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
                      ":hover": {
                        boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                {profilePicture && (
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: 2,
                      borderRadius: 2,
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={profilePicture.imageUrl}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        borderRadius: "10px",
                        border: "1px solid #ddd",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRemoveImage}
                      sx={{
                        marginTop: "1rem",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                        ":hover": {
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
                        },
                      }}
                    >
                      Remove Image
                    </Button>
                  </Paper>
                )}
              </Box>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              // onClick={formik.handleSubmit}
              // onSubmit={()=>(console.log("Formik"))}

              sx={{ marginTop: "1rem", backgroundColor: "#1E4170" }}
            >
              Add Employee
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCancel}
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default EmployeeForm;
