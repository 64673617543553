import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Notification = () => {
  // State variables
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const employeeId = localStorage.getItem("employeeid");

  // Effect to fetch notifications when the component mounts
  useEffect(() => {
    if (employeeId) {
      fetchNotifications(employeeId);
    } else {
      setNotifications([]); // Clear notifications when employeeId is undefined
    }
  }, [employeeId]);

  // Fetch notifications from the server
  const fetchNotifications = (employeeId) => {
  fetch(`${process.env.REACT_APP_API_URL}/api/notifications/recipient/${employeeId}`)
    .then((response) => response.json())
    .then((data) => {
      if (Array.isArray(data)) {
        setNotifications(data);
      } else {
        // Handle the case where data is not an array
        console.error("Data is not an array:", data);
      }
    })
    .catch((error) => {
      console.error("Error fetching notifications: ", error);
    });
};

  // Handle opening the notification menu
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the notification menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle clicking on a notification to delete it
  const handleNotificationClick = (notificationId) => {
    fetch(`${process.env.REACT_APP_API_URL}api/notifications/${notificationId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        // Remove the clicked notification from the notifications array
        const updatedNotifications = notifications.filter(
          (notification) => notification.id !== notificationId
        );
        setNotifications(updatedNotifications);
      })
      .catch((error) => {
        console.error("Error deleting notification: ", error);
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        size="large"
        aria-label="notifications"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.length === 0 ? (
          <MenuItem onClick={handleClose}>
            {employeeId ? "You have no notifications." : "Employee ID is undefined."}
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification.id)}
            >
              {notification.message}
            </MenuItem>
          ))
        )}
      </Menu>
    </Box>
  );
};

export default Notification;
