import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Paper, Typography, TextField, Grid, Button, Box } from "@mui/material";

import { theme } from "../../Theme";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const ManagerForm = () => {
  const token = localStorage.getItem("token");

  const validationSchema = yup.object({
    name: yup.string().required("Branch Name is required"),
    phone_number: yup.string().required("Phone Number is required"),
    address: yup.string().required("Address is required"),
    email: yup.string().required("Email is required"),
    website: yup.string().required("Website Link is required")
   
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
      address: "",
      email: "",
      website: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const branchData = {
          company_id: 1, 
          name: values.name,
          email: values.email,
          address: values.address,
          website: values.website,
          phone_number: values.phone_number,
        };

        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/branch`,
          branchData,
          config
        );

        if (response.status === 201) {
          formik.resetForm();
          navigate("/all-employee");
          toast.success("Branch added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Async Function Error:", error);
      }
    },
  });
  const navigate = useNavigate();
  const handleCancel = () => {
    formik.resetForm();
    navigate("/all-employee");
  };
  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
          Add Branch
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: " 0 15px 25px #0000004d",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Branch
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  required
                  sx={{
                    marginBottom: "0.5rem",
                    color: theme.palette.primary.main,
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="phone_number"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  required
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Website"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "1rem",
                  marginLeft: "1rem",
                  background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                }}
              >
                Add Branch
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={handleCancel}
                sx={{
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default ManagerForm;
