import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import EventIcon from "@mui/icons-material/Event";
import TimerIcon from "@mui/icons-material/Timer";
import AverageTimeIcon from "@mui/icons-material/AccessTime";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import { theme } from "../../Theme";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

const EmployeeManagementTable = () => {
  const [todayAttendance, setTodayAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString.replace(/-/g, '/')).toLocaleDateString("en-US", options);
  };

  const formatTime = (timeString) => {

    if (!timeString || timeString.includes("Average time not reached yet") || timeString.includes("Average time not applicable")) {
      return timeString;
    }

    
    const options = { hour: "numeric", minute: "numeric", second: "numeric", hour12: false };
    return new Date(timeString).toLocaleTimeString("en-US", options);
  };

  const formatWithAmPmTime = (timeString) => {
    if (!timeString || timeString.includes("Ending time not yet reached") || timeString.includes("Employee did not check out")  ) {
      return timeString;
    }

    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return new Date(timeString).toLocaleTimeString('en-US', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/getDailyAttendanceWithBreakTime`;

        if (user_info.role === 'hr') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/getDailyAttendanceWithBreakTime/hrdepartment/${user_info.id}`;
        }

        const response = await axios.get(apiUrl);
        setTodayAttendance(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(todayAttendance);
    XLSX.utils.book_append_sheet(wb, ws, "TodayAttendance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "TodayAttendance.xlsx");
  };

  return (
    <>
      <Grid sx={{ width: "100%", marginInline: "auto", marginTop: "6rem" }}>
        <Box sx={{ width: "98%", marginInline: "auto" }}>
          <Typography sx={{ marginBottom: "2rem" }} variant="h5">
            Today Attendance
          </Typography>
          <AppBar position="static" color="primary">
            <Toolbar sx={{ display: "flex", justifyContent: { xs: "end", sm: "space-between" } }}>
              <Typography variant="h6 " sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" } }}>
                Attendance
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextFieldStyled
                  sx={{ marginLeft: "2rem" }}
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{ startAdornment: <SearchIcon sx={{ color: theme.palette.secondary.main }} /> }}
                />
                <IconButton color="inherit" onClick={exportToExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} alt="Excel" />
                  </Tooltip>{" "}
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <TableContainer sx={{ width: "100%", backgroundColor: "white" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>Date</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>Check-in</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>Break</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>Check-out</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>Average Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todayAttendance
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="center">{formatDate(item.date)}</TableCell>
                      <TableCell align="center">{formatWithAmPmTime(item.checkIn)}</TableCell>
                      <TableCell align="center">{formatWithAmPmTime(item.breakTime)}</TableCell>
                      <TableCell align="center">{formatWithAmPmTime(item.checkOut)}</TableCell>
                      <TableCell align="center">{item.averageTime}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={todayAttendance.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default EmployeeManagementTable;
