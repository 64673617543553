import { configureStore } from "@reduxjs/toolkit";
import employeesReducer from "./employees/employeesSlice";
import managerReducer from "./Manager/managerSlice";
import companyReducer from "./companies/companySlice";
import departmentReducer from "./departments/departmentSlice";
import roleReducer from "./role/roleSlice";

const store = configureStore({
  reducer: {
    employees: employeesReducer,
    manager: managerReducer,
    company:companyReducer,
    department: departmentReducer,
    role:roleReducer,
  },
});

export default store;
