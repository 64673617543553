import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import * as XLSX from "xlsx";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { AiFillFileExcel } from "react-icons/ai";
import { theme } from "../../Theme";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import axios from "axios";
import { setSelectedEmployee } from "../../redux/employees/employeesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

const StudentManagementTable = () => {
  const [employeeData, setEmployeeData] = useState([]);
  console.log("setEmployeeData", employeeData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const [departments, setDepartments] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const token = localStorage.getItem("token");

  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmplloyee
  );

  useEffect(() => {
    async function fetchEmployeeData() {
      const user_info = JSON.parse(localStorage.getItem('user_info'));
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/userinfo`;

        if (user_info.role === 'admin') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/userinfo`;
        } else if (user_info.role === 'hr') {
          apiUrl = `${process.env.REACT_APP_API_URL}/api/userinfo/hrbranch/${user_info.branch_id}`;
        }
        const response = await axios.get(
          apiUrl,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        // Filter employees with role "employee"
        const employeeDataWithRole = response?.data.filter(
          (employee) => employee.role === "employee"
        );

        setEmployeeData(employeeDataWithRole);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    }

    fetchEmployeeData();
  }, []);

  useEffect(() => {
    async function fetchDepartments() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/departments`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        const allDepartments = response.data;
        console.log(allDepartments, "response kuch ");
        setDepartments(allDepartments);
        console.log("my deparments are here:", departments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    }

    fetchDepartments();
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const getDepartmentNameById = (departmentId) => {
    const department = departments.find((dep) => dep.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  // const openAddLeaveDialog = () => {
  //   setAddLeaveDialogOpen(true);
  // };

  // const closeAddLeaveDialog = () => {
  //   setAddLeaveDialogOpen(false);
  // };

  const NavigatingPage = () => {
    navigate("/add-employee");
  };

  // const handleCheckboxChange = (studentId) => {
  //   setSelectedStudents((prevSelected) =>
  //     prevSelected.includes(studentId)
  //       ? prevSelected.filter((id) => id !== studentId)
  //       : [...prevSelected, studentId]
  //   );
  // };

  const handleEditClick = (employee) => {
    dispatch(setSelectedEmployee(employee));
    navigate("/edit-employee");
    console.log(selectedEmployee, "selectedEmployee");
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteClick = (employee) => {
    setCurrentEmployee(employee);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = async (employee) => {
    setIsDeleteModalOpen(false);
    const id = currentEmployee?.id;
    console.log(id);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/userinfo/${id}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log("Employee deleted successfully!");
      toast.success("Employee Deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmployeeData((prevData) => prevData.filter((emp) => emp.id !== id));
    } catch (error) {
      console.error("Error deleting Employee:", error);
    }
  };
  const handleRowClick = (employee) => {
    const employeeId = employee.id;
    dispatch(setSelectedEmployee({ employee, employeeId }));

    localStorage.setItem("selectedEmployeeId", employeeId);

    navigate("/employee-detail");
  };
  // const handleCellClick = (employeeId) => {
  //   console.log("Clicked on employee with ID:", employeeId);
  // };

  const generateExcel = () => {
    const workbook = XLSX.utils.book_new();
    const data = employeeData; // Your table data

    // Create a worksheet and add data to it
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

    // Create a blob from the workbook and initiate a download
    XLSX.writeFile(workbook, "employee_data.xlsx");
  };

  const filteredEmployeeData = employeeData.filter((employee) =>
    employee.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredEmployeeData, "filteredEmployeeData");
  return (
    <>
      <Grid
        container
        justifyContent="center"
        p="1rem"
        gap={5}
        width="100vw"
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginInline: "auto",
            overflowX: "auto",
          }}
        >
          <Typography variant="h5">All Employees</Typography>
          <Paper sx={{ marginTop: "2rem" }}>
            <AppBar position="static" color="primary">
              <Toolbar sx={{ display: "flex", justifyContent: { xs: "end" } }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  All Employees
                </Typography>
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />

                <IconButton color="inherit" onClick={NavigatingPage}>
                  <Tooltip title="Add new user" arrow>
                    <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
                  </Tooltip>
                </IconButton>
                <IconButton color="inherit" onClick={generateExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} alt="" />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>

            <div
              style={{
                width: "100%",
                marginInline: "auto",
                overflowX: "auto",
                overflowY: isDrawerOpen ? "auto" : "hidden",
              }}
            >
              <TableContainer sx={{ overflowX: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Department</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Position</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Gender</TableCell>
                      <TableCell
                        sx={{
                          width: "13%",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                      >
                        Mobile Number
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Joining Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 600,
                          paddingLeft: "3.2rem",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEmployeeData?.map((employee) => (
                      <TableRow
                        key={employee.id}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <TableCell onClick={() => handleRowClick(employee)}>
                          {" "}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexGrow: 1,
                            }}
                          >
                            <Avatar
                              alt={employee.firstname}
                              src={process.env.REACT_APP_API_URL+"\\"+employee.profile_picture}
                              sx={{ marginRight: 1 }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(employee)}>
                          <Typography key={employee.id}>
                            {employee.firstname + " " + employee.lastname}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(employee)}>
                          {getDepartmentNameById(employee.department_id)}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(employee)}>
                          {employee.position}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(employee)}>
                          {employee.gender}
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(employee)}
                          sx={{
                            width: "13%",
                            textAlign: "center",
                          }}
                        >
                          {employee.phone_number}
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(employee)}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {employee.email}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(employee)}>
                          {new Date(
                            employee.employment_date
                          ).toLocaleDateString("en-GB")}
                        </TableCell>

                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton>
                              <Tooltip title="Edit" arrow>
                                <EditIcon
                                  onClick={(e) => {
                                    handleEditClick(employee);
                                  }}
                                  sx={{ color: theme.palette.editIcon.main }}
                                />
                              </Tooltip>
                            </IconButton>

                            <IconButton>
                              <Tooltip title="Delete" arrow>
                                <DeleteOutlineIcon
                                  sx={{ color: theme.palette.deleteIcon.main }}
                                  onClick={() => handleDeleteClick(employee)}
                                />
                              </Tooltip>
                            </IconButton>
                            <IconButton>
                              <Tooltip title="See Details" arrow>
                                <RemoveRedEyeIcon
                                  onClick={() => handleRowClick(employee)}
                                  sx={{ color: theme.palette.primary.main }}
                                />
                              </Tooltip>
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredEmployeeData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </Paper>
        </Box>
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentManagementTable;
