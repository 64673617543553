import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { theme } from "../../Theme";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useEffect, useState } from "react";
import TimePickerValue from "./TimePicker";
import TimePickerValue2 from "./TimePicker2";
import { useNavigate } from "react-router-dom";

const ManagerForm = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [getValue, setGetValue] = useState("");
  const [setValue, setSetValue] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate()
  useEffect(() => {
    console.log(getValue, "check Out");
  }, [getValue]);
  // console.log(setValue, "check in");
  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/branch/`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log("branch data:", response.data);
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies data:", error);
      }
    }

    fetchCompanies();
  }, []);

  useEffect(() => {
    async function fetchDepartments() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/branch/`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 200) {
          setDepartments(response.data);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    }

    fetchDepartments();
  }, []);

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Create a department object with the required data
        const departmentData = {
          name: values.name,
          branch_id: selectedCompany,
          starting_time: setValue,
          ending_time: getValue,
        };

        // Send a POST request to add the department
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/departments`,
          departmentData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 201) {
          formik.resetForm();
          toast.success("Department added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSelectedCompany("");
        }
      } catch (error) {
        console.error("Async Function Error:", error);
      }
    },
  });

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleCancel = () => {
    formik.resetForm();
    navigate("/all-departments");
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
          Add Department
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: " 0 15px 25px #0000004d",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Department
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  required
                  sx={{
                    marginBottom: "0.5rem",
                    color: theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="department-label">Select a Branch</InputLabel>
                  <Select
                    labelId="department-label"
                    id="department"
                    label="Select a Branch"
                    name="department"
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    required
                    sx={{}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {companies?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TimePickerValue
                  setSetValue={setSetValue}
                  checkValue="Check In Time"
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ heigth: "1rem" }}>
                <TimePickerValue2
                  setGetValue={setGetValue}
                  checkValue="Check Out Time"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
                background: "linear-gradient(0.7turn, #55d7b3, #203341)",
              }}
            >
              Add Department
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCancel}
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default ManagerForm;
