import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import { theme } from "../../Theme";

const GraphForThisWeekAttendance = () => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({ /* ... existing options ... */ });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/getThisWeekAttendanceSummary`;
        const response = await axios.get(apiUrl);
        const weeklyData = response.data;
        const currentDate = new Date();

        const categories = weeklyData.present.map((dayData) => dayData.date);
        const presentData = weeklyData.present.map((dayData) => dayData.count);
        const absentData = weeklyData.absent.map((dayData, index) => {
          // Parse the date string to a Date object
          const dataDate = new Date(categories[index]);
          // If the date is in the future, return 0, otherwise return the actual count
          return dataDate > currentDate ? 0 : dayData.count;
        });
        const leaveData = weeklyData.leave.map((dayData) => dayData.count); // Assuming 'leave' data is correct as is

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: { ...prevOptions.xaxis, categories },
        }));
        setSeries([
          {
            name: "Present",
            type: 'column',
            data: presentData,
          },
          {
            name: "Absent",
            type: 'column',
            data: absentData,
          },
          {
            name: "Leave",
            type: 'line',
            data: leaveData,
          },
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const chart = new ApexCharts(
      document.querySelector("#thisWeekChart"),
      options
    );
    chart.render();
  }, [options]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "1rem",
        boxShadow: "0 10px 10px #0000004d",
        padding: "1rem",
      }}
    >
       <Typography
          mt={2}
          ml={2}
          p={3}
          sx={{ fontWeight: 600, width: "70%" }}
          variant="h5"
        >
         This Week Attendance
        </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <div id="thisWeekChart">
          <ApexCharts
            options={options}
            series={series}
            type="line"
            height={350}
          />
        </div>
      )}
    </Box>
  );
};

export default GraphForThisWeekAttendance;
