import React, { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { Box, Typography } from "@mui/material";
import { theme } from "../../Theme";

const Graph = () => {
  // Your data for the chart
  const chartData = {
    labels: ["Present", "Absent", "Holiday Leave", "Weekend"],
    series: [44, 55, 43, 22], // Replace with your actual data values
  };

  const chartOptions = {
    labels: chartData.labels,
    chart: {
      width: 380,
      type: "pie",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#59B97D", "#EEA11D", "#5AB3F6", "#907CE4"], // Define colors here
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "1rem",
        boxShadow: "0 10px 10px #0000004d",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography mt={2} ml={2}>
          Weekly Attendance
        </Typography>
        <ApexCharts
          options={chartOptions}
          series={chartData.series}
          type="pie"
          height={450}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {chartData.labels.map((label, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: chartOptions.colors[index],
                marginRight: "8px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {chartData.series[index]}
            </div>
            <span>{label}</span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Graph;
