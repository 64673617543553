import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  IconButton,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";

import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import { theme } from "../../Theme";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";

const LeaveBalanceTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveRequests, setLeaveRequests] = useState([]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get("http://localhost:4000/api/userinfo/");
        const users = response.data;
        
        // Transform user data to match the leave request format
        const transformedLeaveRequests = users.map(user => ({
          id: user.user_id,
          name: `${user.firstname} ${user.lastname}`,
          role: user.role,
          pendingLeaves: user.leavesdetails.pendingLeaves, 
          currentYearLeaves: user.leavesdetails.currentYearLeave,
          usedLeaves: user.leavesdetails.usedLeaves,
          totalAcceptedLeaves: user.leavesdetails.totalAcceptedLeaves,
          totalRejectedLeaves: user.leavesdetails.totalRejectedLeaves,
          image: user.profile_picture 
        }));
        setLeaveRequests(transformedLeaveRequests);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const exportToExcel = () => {
    const formattedData = leaveRequests.map((request) => ({
      Name: request.name,
      "Pending Leaves": request.pendingLeaves,
      "Current Year Leaves": request.currentYearLeaves,
      "Used Leaves": request.usedLeaves,
      "Total Accepted Leaves": request.totalAcceptedLeaves,
      "Total Rejected Leaves": request.totalRejectedLeaves,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LeaveBalance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "LeaveBalance.xlsx");
  };

  const filteredLeaveRequests = leaveRequests.filter(
    (request) =>
    request.role !== "admin" && (
      request.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.pendingLeaves.toString().includes(searchQuery.toLowerCase()) ||
      request.currentYearLeaves.toString().includes(searchQuery.toLowerCase()) ||
      request.usedLeaves.toString().includes(searchQuery.toLowerCase()) ||
      request.totalAcceptedLeaves.toString().includes(searchQuery.toLowerCase()) ||
      request.totalRejectedLeaves.toString().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography variant="h5">Leave Balance</Typography>
        <Paper sx={{ marginTop: "2rem" }}>
          <AppBar position="static" color="primary">
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "space-between",
                },
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Leave Balance</Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />

                <IconButton color="inherit" onClick={exportToExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} alt="Excel Icon" />
                  </Tooltip>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>

                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Pending Leaves
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Current Year Leaves
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Used Leaves
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Total Accepted Leaves
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                    Total Rejected Leaves
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLeaveRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((request) => (
                      <TableRow key={request.id}>
                      <TableCell style={{ textAlign: "center" }}>
                        <Avatar
                          alt={request.name}
                          src={`${process.env.REACT_APP_API_URL}${request.image}`}
                          sx={{ marginRight: 1 }}
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.name} {request.role}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.pendingLeaves}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.currentYearLeaves}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.usedLeaves} 
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.totalAcceptedLeaves}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {request.totalRejectedLeaves}
                      </TableCell>
                    </TableRow>
             
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredLeaveRequests.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Box>
    </Grid>
  );
};

export default LeaveBalanceTable;
