import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
} from "@mui/material";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { useNavigate } from "react-router";
import { theme } from "../../Theme";
import axios from "axios";
import UserDetailsModal from "./UserModal";
import AcceptRejectDialog from "./StatusModal";

const LeaveRequestDashboard = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isAcceptRejectModalOpen, setAcceptRejectModalOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leave`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        const leaveRequestsData = response.data;
        setLeaveRequests(leaveRequestsData);
      } catch (error) {
        console.error("Error fetching leave requests:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setSelectedLeaveRequest(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSelectedLeaveRequest(null);
  };

  const handleSeeAllClick = () => {
    navigate("/all-leaves");
  };

  const handleDetailsClick = (employeeId) => {
    const specificRequest = leaveRequests.find(
      (request) => request.employeeId === employeeId
    );

    if (specificRequest) {
      console.log("Specific Request Data:", specificRequest);
      setSelectedUser(specificRequest);
      setIsUserDetailsModalOpen(true);
    } else {
      console.log("Request not found for employeeId:", employeeId);
    }
  };

  const handleOpenAcceptRejectModal = (LeaveId) => {
    setAcceptRejectModalOpen(true);
    setSelectedLeaveId(LeaveId);
  };

  const handleCloseAcceptRejectModal = () => {
    setAcceptRejectModalOpen(false);
  };

  const updateStatusInUI = (LeaveId, newStatus) => {
    // Create a copy of the leaveRequests array
    const updatedLeaveRequests = leaveRequests.map((request) => {
      if (request.id === LeaveId) {
        // Update the status of the specific request
        return { ...request, status: newStatus };
      }
      return request;
    });

    // Update the state with the new array
    setLeaveRequests(updatedLeaveRequests);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flex: 1 }}>
        <Paper
          sx={{
            marginTop: "0rem",
            width: "100%",
            boxShadow: "0 10px 10px #0000004d",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <AppBar position="static" color="secondary">
            <Toolbar sx={{ display: "flex", justifyContent: "start" }}>
              <Typography sx={{ marginRight: "2rem",color:"white" }} variant="h6">
                Leave Requests
              </Typography>
            </Toolbar>
          </AppBar>

          <TableContainer>
            <Table>
              <TableHead sx={{ width: "100%" }}>
                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Leave From</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Leave To</TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Number of Days
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequests
                  .filter(
                    (request) =>
                      request.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      request.reason
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      request.from_date
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      request.to_date
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      request.status
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((request) => (
                    <TableRow
                      key={request.id}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          request.id === selectedLeaveRequest
                            ? "lightblue"
                            : "white",
                        "&:hover": {
                          backgroundColor: "white",
                          color: theme.palette.primary.main,
                          borderRadius: "20px",
                        },
                      }}
                      onClick={() => {
                        handleDetailsClick(request.employeeId);
                        setSelectedLeaveRequest(request.id);
                      }}
                    >
                      <TableCell>
                        <Avatar
                          alt={request.name}
                          src={`https://i.pravatar.cc/40?u=${request.id}`}
                          sx={{ marginLeft: 2 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {request.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {new Date(request.from_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </TableCell>
                      <TableCell>
                        {new Date(request.to_date).toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {request.number_of_days}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleOpenAcceptRejectModal(request.id);
                        }}
                      >
                        <div
                          style={{
                            width: 70,
                            height: 30,
                            textAlign: "center",
                            color:
                              request.status === "Accepted" ? "green" : "red",
                            border: `2px solid ${
                              request.status === "Accepted" ? "green" : "red"
                            }`,
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {request.status}
                        </div>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            handleDetailsClick(request.employeeId);
                          }}
                        >
                          <RemoveRedEyeIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "17px",
              }}
            >
              <Typography
                onClick={handleSeeAllClick}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    color: "blue",
                  },
                }}
              >
                See all
              </Typography>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                count={leaveRequests.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              />
            </div>
          </TableContainer>
        </Paper>
      </div>

      <UserDetailsModal
        open={isUserDetailsModalOpen}
        onClose={() => setIsUserDetailsModalOpen(false)}
        user={selectedUser}
      />

      {/* Accept/Reject Modal */}
      <AcceptRejectDialog
        open={isAcceptRejectModalOpen}
        onClose={handleCloseAcceptRejectModal}
        UserLeaveId={selectedLeaveId}
        updateStatusInUI={updateStatusInUI}
      />
    </div>
  );
};

export default LeaveRequestDashboard;
