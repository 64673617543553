import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from "@mui/material";

const NotificationsSettings = () => {
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [eventNotifications, setEventNotifications] = useState(true);
  const [securityNotifications, setSecurityNotifications] = useState(true);

  const handleEmailNotificationsToggle = () => {
    setEmailNotifications(!emailNotifications);
  };

  const handleEventNotificationsToggle = () => {
    setEventNotifications(!eventNotifications);
  };

  const handleSecurityNotificationsToggle = () => {
    setSecurityNotifications(!securityNotifications);
  };

  return (
    <Box>
      <List>
        <ListItem>
          <ListItemText sx={{ fontWeight: 600 }}>
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              Email Notifications
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleEmailNotificationsToggle}
              checked={emailNotifications}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              Event Notifications
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleEventNotificationsToggle}
              checked={eventNotifications}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText sx={{ fontWeight: 600 }}>
            <Typography sx={{ fontWeight: 600 }}>
              {" "}
              Security Notifications
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleSecurityNotificationsToggle}
              checked={securityNotifications}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Box>
  );
};

export default NotificationsSettings;
