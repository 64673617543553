import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DeckIcon from "@mui/icons-material/Deck";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DomainAddIcon from "@mui/icons-material/DomainAdd";

import {
  Avatar,
  Collapse,
  Menu,
  MenuItem,
  Tooltip,
  // Select,
  // MenuItem,
  // Button,
  // Paper,
  // Badge,
  // Avatar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

import SettingsIcon from "@mui/icons-material/Settings";

import image from "../../demo.jpg";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../Assets/images/authPages/1 (1).png"

import MenuAppBar from "./Notification.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedResponse } from "../../redux/role/roleSlice";
import { useState } from "react";
import { debounce } from "lodash";

import {
  AttendanceConstants,
  EmployeeNavListConstants,
  CompanyList,
  DepartmentList,
  HolidaysConstants,
  HrNavList,
  LeaveManagementConstants,
  EmployeePageConstants,
  LeaveManagementHRConstants,
} from "../../constants/NavListConstants.js";
import { element } from "prop-types";

let drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav() {
  const [openEmployeeMenu, setOpenEmployeeMenu] = useState(false);
  const [openLeaveMenu, setOpenLeaveMenu] = useState(false);
  const [openAttendanceMenu, setOpenAttendanceMenu] = useState(false);
  const [openHolidayMenu, setOpenHolidayMenu] = React.useState(false);
  const [openHrMenu, setOpenHrMenu] = React.useState(false);
  const [openCompanyMenu, setOpenCompanyMenu] = React.useState(false);
  const [openDepartmentMenu, setOpenDepartmentMenu] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const selectedResponse = useSelector((state) => state.role.selectedResponse);
  const [role, setRole] = React.useState(selectedResponse?.role);
  const value = JSON.parse(localStorage.getItem("user_info"));

  React.useEffect(() => {
    if (value) {
      setRole(value.role);
    }
  }, [value]);

  const employeeMenuHandler = () => setOpenEmployeeMenu((prev) => !prev);
  const leaveMenuHandler = () => setOpenLeaveMenu((prev) => !prev);
  const attendanceMenuHandler = () => setOpenAttendanceMenu((prev) => !prev);
  const holidayMenuHandler = () => setOpenHolidayMenu((prev) => !prev);
  const HrHandler = () => setOpenHrMenu((prev) => !prev);
  const CompanyHandler = () => setOpenCompanyMenu((prev) => !prev);
  const DepartmentHandler = () => setOpenDepartmentMenu((prev) => !prev);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);

  const handleMouseEnter = () => {
    if (!menuClicked) {
      setOpen(true);
    }
  };

  const handleMouseLeave = debounce(() => {
    if (!menuClicked) {
      setOpen(false);
      setOpenEmployeeMenu(false);
      setOpenLeaveMenu(false);
      setOpenAttendanceMenu(false);
      setOpenHolidayMenu(false);
      setOpenHrMenu(false);
      setOpenCompanyMenu(false);
      setOpenDepartmentMenu(false);
    }
  }, 200);

  const handleMenuClick = () => {
    setMenuClicked(!menuClicked);
    setOpen(true);
  };

  const handleMenuClickClose = () => {
    setMenuClicked(false);
  };

  const handleDrawerOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  // const handleDrawerCloseHover = () => {
  //   if (open) {
  //     setOpen(false);
  //   }
  // };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  window.addEventListener("storage", (event) => {
    if (event.key === "user_info") {
      logout();
    }
  });

  const handleDrawerClose = () => {
    setOpenEmployeeMenu(false);
    setOpenLeaveMenu(false);
    setOpenAttendanceMenu(false);
    setOpenHolidayMenu(false);
    setOpen(false);
    setOpenHrMenu(false);
    setOpenCompanyMenu(false);
    setOpenDepartmentMenu(false);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const adminInfo = localStorage.getItem("user_info");

  const adminInfoObj = JSON.parse(adminInfo);

  const userNamN = adminInfoObj?.usernamN;

  const checkUserInfo = JSON.parse(localStorage.getItem("user_info"));
  if (!checkUserInfo) {
    navigate("/login");
  }
  const handleMenuToggle = (menuName, menuState, setMenuState) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuName]: !menuState[menuName],
    }));
  };

  const getHeight = () => {
    let height = 100;

    const menuStates = {
      openEmployeeMenu,
      openLeaveMenu,
      openAttendanceMenu,
      openHolidayMenu,
      openHrMenu,
      openCompanyMenu,
      openDepartmentMenu,
    };

    Object.keys(menuStates).forEach((menuName) => {
      if (menuStates[menuName]) {
        height += 20;
        if (menuName === "openHrMenu") {
          height += 30;
        }
      }
    });

    return `${height}%`;
  };

  const userInfo = JSON.parse(localStorage.getItem("user_info"));

// Check if the userInfo object exists and has the profile_picture field
const profilePicturePath = process.env.REACT_APP_API_URL+"\\"+userInfo?.profile_picture;
console.log(profilePicturePath)

  return (
    <>
      <Box>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            sx={{
              background: "linear-gradient(0.7turn, #55d7b3, #203341)",
            }}
          >
            <Toolbar >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleMenuClick}
                edge="start"
                sx={{
                  paddingLeft: "12px",
                  marginRight: 7,
                  background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Toolbar
                display="flex"
                // width="100%"
                sx={{
                  width: "100%",
                  justifyContent: {
                    xs: "end",
                    sm: "space-between",
                    md: "space-between",
                    lg: "space-between",
                    xl: "space-between",
                  },
                }}
              >
                <Typography
                  justifyContent="start"
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  Clokify Management System
                </Typography>
                <Box display="flex" justifyContent="end" alignItems={"center"}>
                  <Tooltip justifyContent="end">
                    <MenuAppBar />
                  </Tooltip>
                  <Typography
                        sx={{
                          color: "white",
                          marginRight: "1rem",
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                        }}
                      >
                        {userNamN}
                      </Typography>
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{
                        p: 0,
                        borderRadius: "1rem",
                        display: {
                          xs: "none",
                          sm: "flex",
                          md: "flex",
                          lg: "flex",
                          xl: "flex",
                        },
                      }}
                    >
                    
                    <Avatar alt={userNamN} src={profilePicturePath} />
                    </IconButton>
                
                </Box>
              </Toolbar>
            </Toolbar>
          </AppBar>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Drawer
              variant="permanent"
              open={open}
              sx={{
                position: {
                  xs: open ? "absolute" : "static",
                  sm: "static",
                },
              }}
            >
              {role === "admin" ? (
                <div
                  style={{
                    background: "linear-gradient(0turn, #55d7b3, #203341)",
                    height: getHeight(),
                    width: "18%",
                    position: "absolute",
                  }}
                ></div>
              ) : role === "hr" ? (
                <div
                  style={{
                    background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                    height: getHeight(),

                    width: "18%",
                    position: "absolute",
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    background: "linear-gradient(0turn, #55d7b3, #203341)",
                    height: "100%",
                    width: "18%",
                    position: "absolute",
                  }}
                ></div>
              )}

              <DrawerHeader
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                {/* <Typography variant="h5" ml={3}>
                  CLOKIFY
                </Typography> */}
             <Avatar src={Logo} alt="clockify" sx={{ width: 140 ,margin:"0px 40px" }} />

                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon onClick={handleMenuClickClose} />
                  )}
                </IconButton>
              </DrawerHeader>

              <Divider />
              <List sx={{ marginLeft: ".5rem", gap: ".5rem" }}>
                <ListItemButton
                  component={Link}
                  to="/"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: open ? "white" : theme.palette.primary.main,

                      minWidth: 0,
                      mr: open ? 2.5 : 0,
                      ml: open ? -2 : 0,
                      display: "flex",
                      justifyContent: "center",

                      "&:hover": {
                        backgroundColor: "white",
                        color: theme.palette.primary.main,
                        borderRadius: "20px",
                      },
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                {/* Employee Page Sidenav List */}
                {role === "employee" ? (
                  // Employee menu
                  EmployeePageConstants?.map((employee) => (
                    <ListItemButton
                      component={Link}
                      to={employee.to}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      key={employee.id}
                    >
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,
                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -2 : 0,
                          display: "flex",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                      >
                        {employee.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={employee.title}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  ))
                ) : role === "hr" ? (
                  <>
                    {/* Employee admin menu */}
                    <ListItem button onClick={employeeMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,
                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <AccountCircleIcon
                          sx={{
                            justifyContent: open ? "flex-start" : "center",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Employee"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    {open && (
                      <Collapse
                        in={openEmployeeMenu}
                        timeout="auto"
                        unmountOnExit
                        sx={{ marginLeft: "1.7rem" }}
                      >
                        <List component={"div"} disablePadding>
                          {EmployeeNavListConstants?.map((employee) => (
                            <ListItemButton
                              onClick={handleDrawerOpen}
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                              key={employee?.id}
                            >
                              <ListItem button key={employee?.id}>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItem>
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    )}
                    <ListItem button onClick={leaveMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "start",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <WysiwygIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Leave Management"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openLeaveMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component="div" disablePadding>
                        {open &&
                          LeaveManagementHRConstants?.map((employee) => (
                            <ListItemButton
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              disableRipple
                              key={employee?.id}
                            >
                              <ListItem button>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={attendanceMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "start",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <NoteAltIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Attendance"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openAttendanceMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          AttendanceConstants?.map((attendance) => (
                            <ListItemButton
                              component={Link}
                              to={attendance.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button key={attendance?.id}>
                                <ListItemText
                                  primary={attendance?.title}
                                ></ListItemText>
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <>
                    {/* Employee admin menu */}
                    <ListItem button onClick={employeeMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,
                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <AccountCircleIcon
                          sx={{
                            justifyContent: open ? "flex-start" : "center",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Employee"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openEmployeeMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          EmployeeNavListConstants?.map((employee) => (
                            <ListItemButton
                              onClick={handleDrawerOpen}
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              disableRipple
                              key={employee?.id}
                            >
                              <ListItem button key={employee?.id}>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={leaveMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "start",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <WysiwygIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Leave Management"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openLeaveMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component="div" disablePadding>
                        {open &&
                          LeaveManagementConstants?.map((employee) => (
                            <ListItemButton
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              disableRipple
                              key={employee?.id}
                            >
                              <ListItem button>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={attendanceMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "start",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <NoteAltIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Attendance"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openAttendanceMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          AttendanceConstants?.map((attendance) => (
                            <ListItemButton
                              component={Link}
                              to={attendance.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button key={attendance?.id}>
                                <ListItemText
                                  primary={attendance?.title}
                                ></ListItemText>
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={holidayMenuHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "start",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <DeckIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Holiday"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {open ? (
                        openHolidayMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openHolidayMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component="div" disablePadding>
                        {open &&
                          HolidaysConstants?.map((holiday) => (
                            <ListItemButton
                              component={Link}
                              to={holiday.to}
                              key={holiday?.id}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button>
                                <ListItemText
                                  primary={holiday?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={HrHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <ManageAccountsIcon
                          sx={{
                            justifyContent: open ? "flex-start" : "center",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="HR Managers"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openHrMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          HrNavList?.map((employee) => (
                            <ListItemButton
                              onClick={handleDrawerOpen}
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button key={employee?.id}>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                ></ListItemText>
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={CompanyHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <ApartmentIcon
                          sx={{
                            justifyContent: open ? "flex-start" : "center",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Branches"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openCompanyMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          CompanyList?.map((employee) => (
                            <ListItemButton
                              onClick={handleDrawerOpen}
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button key={employee?.id}>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                ></ListItemText>
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                    <ListItem button onClick={DepartmentHandler}>
                      <ListItemIcon
                        sx={{
                          color: open ? "white" : theme.palette.primary.main,

                          minWidth: 0,
                          mr: open ? 2.5 : 0,
                          ml: open ? -1.5 : 0,
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "white",
                            color: theme.palette.primary.main,
                            borderRadius: "20px",
                          },
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <DomainAddIcon
                          sx={{
                            justifyContent: open ? "flex-start" : "center",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Departments"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                      {open ? (
                        openEmployeeMenu ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={openDepartmentMenu}
                      timeout="auto"
                      unmountOnExit
                      sx={{ marginLeft: "1.7rem" }}
                    >
                      <List component={"div"} disablePadding>
                        {open &&
                          DepartmentList?.map((employee) => (
                            <ListItemButton
                              onClick={handleDrawerOpen}
                              component={Link}
                              to={employee.to}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent", // Set the hover background color
                                },
                              }}
                              disableRipple
                            >
                              <ListItem button key={employee?.id}>
                                <ListItemText
                                  primary={employee?.title}
                                  sx={{ opacity: open ? 1 : 0 }}
                                ></ListItemText>
                              </ListItem>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>{" "}
                  </>
                )}

                <Divider />

                <ListItemButton
                  component={Link}
                  to="/settings"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: open ? "white" : theme.palette.primary.main,

                      minWidth: 0,
                      mr: open ? 2.5 : 0,
                      ml: open ? -2 : -0.5,
                      justifyContent: "start",
                      "&:hover": {
                        backgroundColor: "white",
                        color: theme.palette.primary.main,
                        borderRadius: "20px",
                      },
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Settings"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={handleLogout}
                  component={Link}
                  to="/login"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent", // Set the hover background color
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: open ? "white" : theme.palette.primary.main,

                      minWidth: 0,
                      mr: open ? 2.5 : 0,
                      ml: open ? -1.5 : -0.2,
                      justifyContent: "start",
                      "&:hover": {
                        backgroundColor: "white",
                        color: theme.palette.primary.main,
                        borderRadius: "20px",
                      },
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Log out"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </List>
            </Drawer>
          </div>
        </Box>
      </Box>
    </>
  );
}
