import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const AdminProfileSettings = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteAccount = () => {
    // Handle the account deletion logic here
    // This is a placeholder and should be replaced with actual logic
    alert("Account deleted.");
    setDeleteModalOpen(false);
  };
  return (
    <Grid>
      <Box marginTop={"1rem"}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          My Profile
        </Typography>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type="password"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                variant="outlined"
                type="date"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                variant="outlined"
                type="date"
                required
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  style: { paddingRight: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <Box marginTop={"2rem"}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Delete Account
        </Typography>
        <Typography>
          Are you sure you want to delete your account? This action is
          irreversible.
        </Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={handleOpenDeleteModal}
        >
          Delete Account
        </Button>
      </Box>

      {/* Delete Account Confirmation Modal */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete your account? This action is
            irreversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAccount}
            variant="contained"
            color="primary"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminProfileSettings;
