
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { theme } from "../../Theme";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEmployee } from "../../redux/employees/employeesSlice";

export const EditEmployeeForm = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedEmployee = useSelector((state) => state.employees.selectedEmployee);
  
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  console.log(filteredDepartments,"filteredDepartments" ,[selectedEmployee?.department_id || ""])
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/branch/`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setBranches(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
  
    // Only fetch branches if selectedBranch is not set
    if (!selectedBranch) {
      fetchBranches();
    }
  }, [token, selectedBranch]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/departments/`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setDepartments(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [token]);

  useEffect(() => {
    const filterDepartments = () => {
      const filtered = departments.filter((department) => department.branch_id === selectedBranch);
      setFilteredDepartments(filtered);
    };

    filterDepartments();
  }, [selectedBranch, departments]);

  useEffect(() => {
    const fetchSelectedEmployee = async () => {
      if (selectedEmployee && selectedEmployee.userInfo) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/userinfo/${selectedEmployee.userInfo.id}`,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          dispatch(setSelectedEmployee(response.data));
  
          setSelectedBranch(response.data.branch_id || ""); // Assuming branch_id is the property you want to set
            console.log(response.data,"response.data")
          // Set the initial value for filteredDepartments based on the selected employee's department_id
          const initialFilteredDepartments = departments.filter(
            (department) => department.id === response.data.department_id
          );
          console.log(initialFilteredDepartments,"initialFilteredDepartments")
          setFilteredDepartments(initialFilteredDepartments);
        } catch (error) {
          console.error("Error fetching selected employee:", error);
        }
      }
    };
  
    fetchSelectedEmployee();
  }, [token, dispatch, selectedEmployee, departments]);

  const handleBranchChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue !== null) {
      setSelectedBranch(selectedValue);
      formik.handleChange(event);
    }
  };
  

  const handleDepartmentChange = (event) => {
    const selectedValues = event.target.value;
    formik.setFieldValue("department", selectedValues);
    setSelectedDepartmentIds(selectedValues);
  };

  const formik = useFormik({
    initialValues: {
      userName: selectedEmployee?.username || "",
      firstName: selectedEmployee?.firstname || "",
      lastName: selectedEmployee?.lastname || "",
      gender: selectedEmployee?.gender || "",
      phoneNumber: selectedEmployee?.phone_number || "",
      branch: selectedEmployee?.branch_id || "",
      position: selectedEmployee?.position || "",
      department: selectedEmployee?.department_id || "",
      address: selectedEmployee?.address || "",
      email: selectedEmployee?.email || "",
      date_of_birth: selectedEmployee?.date_of_birth
        ? new Date(selectedEmployee.date_of_birth).toISOString().split("T")[0]
        : "",
      salary: selectedEmployee?.salary || "",
      CNIC: selectedEmployee?.cnic || "",
      emergency_contact_name: selectedEmployee?.emergency_contact_name || "",
      emergency_contact_phone_number: selectedEmployee?.emergency_contact_phone_number || "",
      emergency_contact_relation: selectedEmployee?.emergency_contact_relation || "",
    },
    validationSchema: yup.object().shape({
      // Add validation schema as needed
    }),
    onSubmit: async (values) => {
      console.log(selectedBranch,"selectedBranch")
      try {
        const userData = {
          user_id: selectedEmployee.user_id,
          department_id: values.department,
          branch_id: selectedBranch !== "" ? parseInt(selectedBranch) : selectedEmployee?.branch_id,
          email: values.email,
          username: values.userName,
          role: "employee",
          firstName: values.firstName,
          lastName: values.lastName,
          date_of_birth: values.date_of_birth,
          gender: values.gender,
          employment_date: selectedEmployee.employment_date,
          position: values.position,
          employment_status: selectedEmployee.employment_status,
          address: values.address,
          phone_number: values.phoneNumber,
          salary: values.salary,
          cnic: values.CNIC,
          emergency_contact_name: selectedEmployee.emergency_contact_name,
          emergency_contact_phone_number: selectedEmployee.emergency_contact_phone_number,
          emergency_contact_relation: selectedEmployee.emergency_contact_relation,
        };

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/userinfo/${selectedEmployee.id}`,
          userData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setSelectedEmployee(response.data));
          toast.success("Employee updated successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/all-employee");
        }
      } catch (error) {
        console.error("Async Function Error:", error);
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm({
      values: {
        userName: selectedEmployee?.username || "",
        firstName: selectedEmployee?.firstname || "",
        lastName: selectedEmployee?.lastname || "",
        gender: selectedEmployee?.gender || "",
        phoneNumber: selectedEmployee?.phone_number || "",
        branch: selectedEmployee?.branch_id || "",
        position: selectedEmployee?.position || "",
        department: selectedEmployee?.department_id || "",
        address: selectedEmployee?.address || "",
        email: selectedEmployee?.email || "",
        date_of_birth: selectedEmployee?.date_of_birth || "",
        salary: selectedEmployee?.salary || "",
        CNIC: selectedEmployee?.cnic || "",
        emergency_contact_name: selectedEmployee?.emergency_contact_name || "",
        emergency_contact_phone_number: selectedEmployee?.emergency_contact_phone_number || "",
        emergency_contact_relation: selectedEmployee?.emergency_contact_relation || "",
      },
    });
    dispatch(setSelectedEmployee(null));
    navigate("/all-employee");
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
          Edit Employee
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: " 0 15px 25px #0000004d",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Employee
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="userName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                  // required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              
              <Grid item xs={6} sm={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  // required
                  sx={{
                    marginBottom: "0.5rem",
                    color: theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  // required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    label="Gender"
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    required
                    sx={{}}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  required
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
             
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  size="small"
                  style={{ marginBottom: "0.5rem", borderColor: "#1E4170" }}
                >
                  <InputLabel id="branch-label">Branches</InputLabel>
                  <Select
                    labelId="branch-label"
                    id="branch"
                    name="branch"
                    label="Branches"
                    variant="outlined"
                    value={formik.values.branch}
                    onChange={handleBranchChange}
                    error={
                      formik.touched.branch && Boolean(formik.errors.branch)
                    }
                    helperText={formik.touched.branch && formik.errors.branch}
                    style={{ borderColor: "#1E4170" }}
                  >
                    <MenuItem value="" style={{ color: "#1E4170" }}>
                      Select Branch
                    </MenuItem>
                    {loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Position"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.position && Boolean(formik.errors.position)
                  }
                  helperText={formik.touched.position && formik.errors.position}
                  required
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  size="small"
                  style={{ marginBottom: "0.5rem", borderColor: "#1E4170" }}
                >
                  <InputLabel id="department-label">Departments</InputLabel>
                  <Select
                    labelId="department-label"
                    id="department"
                    name="department"
                    label="Departments"
                    variant="outlined"
                    value={formik.values.department}
                    onChange={handleDepartmentChange}
                    multiline
                    error={
                      formik.touched.department &&
                      Boolean(formik.errors.department)
                    }
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                    style={{ borderColor: "#1E4170" }}
                  >
                    <MenuItem value="" style={{ color: "#1E4170" }}>
                      Select Department
                    </MenuItem>
                    {loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      filteredDepartments.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date of Birth"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="date"
                  name="date_of_birth"
                  value={formik.values.date_of_birth}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={{ borderColor: "#1E4170", marginBottom: "0.5rem" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Salary"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  name="salary"
                  value={formik.values.salary}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.salary && Boolean(formik.errors.salary)
                  }
                  helperText={formik.touched.salary && formik.errors.salary}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>{" "}
              <Grid item xs={6} sm={6}>
                <TextField
                  label="CNIC"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="CNIC"
                  value={formik.values.CNIC}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.CNIC && Boolean(formik.errors.CNIC)
                  }
                  helperText={formik.touched.CNIC && formik.errors.CNIC}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
            </Grid>
         
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                style={{ display: "none" }}
                // onChange={handleImageUpload}
              />
              <label htmlFor="image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  color="primary"
                  sx={{ marginBottom: "1rem" }}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // onClick={formik.handleSubmit}
              // onSubmit={()=>(console.log("Formik"))}
              sx={{ marginTop: "1rem", backgroundColor: "#1E4170" }}
            >
            Save
            </Button>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCancel}
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};
