import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCompany: null,
};

const CompaniesSlice = createSlice({
  name: "Companies",
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    resetSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
  },
});

export const { setSelectedCompany ,resetSelectedCompany} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;
