import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EventIcon from '@mui/icons-material/Event';
import TimerIcon from '@mui/icons-material/Timer';
import CheckIcon from '@mui/icons-material/Check';
import AverageTimeIcon from '@mui/icons-material/AccessTime';

const EmployeeAttendanceReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const selectedEmployeeId = localStorage.getItem('selectedEmployeeId');
    axios.get(`${process.env.REACT_APP_API_URL}/api/dbAttendance/userAttendancedate/${selectedEmployeeId}`)
      .then(response => {
        const formattedData = response.data.map(item => {
          const checkInTime = new Date(item.checkInTime);
          const checkOutTime = new Date(item.checkOutTime);
          const averageTime = new Date(checkOutTime - checkInTime);
          const hours = averageTime.getUTCHours();
          const minutes = averageTime.getUTCMinutes();

          return {
            date: checkInTime.toLocaleDateString(),
            checkIn: checkInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
            checkOut: checkOutTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
            averageTime: `${hours}h ${minutes}m`,
          };
        });
        setAttendanceData(formattedData);
      })
      .catch(error => {
        console.error('Error fetching attendance data', error);
      });
  }, []);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to convert date string to yyyy-mm-dd format
  const toYMDFormat = (dateStr) => {
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0];
  };

  const filteredData = attendanceData.filter(
    item => !selectedDate || toYMDFormat(item.date) === selectedDate
  );

  return (
    <Grid container sx={{ width: '100%', marginInline: 'auto' }}>
      <Box sx={{ width: '98%', marginInline: 'auto' }}>
        <TextField
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          sx={{ m: 2 }}
        />
        <TableContainer component={Paper} sx={{ marginBottom: '4rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="center">Check-in</TableCell>
                <TableCell align="center">Check-out</TableCell>
                <TableCell align="center">Average Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell align="center">{item.checkIn}</TableCell>
                    <TableCell align="center">{item.checkOut}</TableCell>
                    <TableCell align="center">{item.averageTime}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default EmployeeAttendanceReport;
