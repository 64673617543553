import { styled } from "@mui/material";
import LoginBackgroudImage from "../../Assets/images/authPages/imagerecovered.jpg";

export const MainLoginDiv = styled("div")({
  width: "100vw",
  height: "100vh",
  // backgroundImage: `url(${LoginBackgroudImage})`,
  background:"#eff5f3",
  backgroundRepeat: " no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  objectFit: "fill",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
