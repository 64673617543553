import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Toolbar,
  Tabs,
  Tab
} from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PieChartIcon from '@mui/icons-material/PieChart';
import EmployeeAttendanceReport from './EmployeeAttendanceReport';
import PieChart from './AttendanceGraph';
import axios from 'axios';

const EmployeeAttendance = () => {
  const [value, setValue] = useState(0);
  const [employeeInfo, setEmployeeInfo] = useState(null);

  useEffect(() => {
    const fetchEmployeeInfo = async () => {
      const selectedEmployeeId = localStorage.getItem('selectedEmployeeId');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userinfo/${selectedEmployeeId}`);
        setEmployeeInfo(response.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeInfo();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        sx={{
          width: '100%',
          marginInline: 'auto',
          marginTop: '6rem',
        }}
      >
        <Grid item xs={12} xl={12} sm={12}>
          <Box sx={{ width: '98%', marginInline: 'auto' }}>
            <Typography sx={{ marginBottom: '2rem' }} variant="h5">
              Employee Attendance
            </Typography>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                width: '100%',
                position: 'relative',
                borderRadius: '.5rem',
              }}
            >
              <Toolbar sx={{ display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{ marginRight: '2rem', color: 'primary' }}
                  variant="h6"
                >
                  Employees Attendance
                </Typography>
              </Toolbar>

              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                aria-label="Profile tabs"
                variant="fullWidth"
              >
                <Tab
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' },
                  }}
                  label={
                    <>
                      <EventNoteIcon />
                      Details
                    </>
                  }
                />
                <Tab
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' },
                  }}
                  label={
                    <>
                      <PieChartIcon />
                      Chart
                    </>
                  }
                />
              </Tabs>

              <CardContent>
                {value === 0 && employeeInfo && (
                  <div className="tab-content">
                    <Card
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '1rem',
                        padding: '1.5rem .5rem',
                        overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: '700' }}>
                          Full Name
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'light', fontSize: '.9rem' }}
                        >
                          {employeeInfo.firstname} {employeeInfo.lastname}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: '700' }}>
                          Full Name
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'light', fontSize: '.9rem' }}
                        >
                          {employeeInfo.firstname} {employeeInfo.lastname}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: '700' }}>
                          Full Name
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'light', fontSize: '.9rem' }}
                        >
                          {employeeInfo.firstname} {employeeInfo.lastname}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: '700' }}>
                          Full Name
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'light', fontSize: '.9rem' }}
                        >
                          {employeeInfo.firstname} {employeeInfo.lastname}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: '700' }}>
                          Full Name
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'light', fontSize: '.9rem' }}
                        >
                          {employeeInfo.firstname} {employeeInfo.lastname}
                        </Typography>
                      </Box>
                    </Card>

                    <Card sx={{ marginTop: '3rem' }}>
                      <EmployeeAttendanceReport />
                    </Card>
                  </div>
                )}

                {value === 1 && (
                  <div className="tab-content">
                    <PieChart />
                  </div>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeAttendance;
