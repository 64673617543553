import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Sort, Filter } from "@mui/icons-material";
import { theme } from "../../Theme";

const AttendanceComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "2rem 0",
        width: "98%",
      }}
    >
      {" "}
      <div style={{ display: "flex", alignitems: "center" }}>
        <div
          style={{
            width: "10px",
            height: "40px",
            background: "linear-gradient(0.7turn, #55d7b3, #203341)",
            marginRight: "10px",
            borderRadius: "2.5rem",
          }}
        ></div>
        <Typography variant="h4" color={theme.palette.primary.main}>
          Attendance History
        </Typography>
      </div>
      <Box>
        <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          startIcon={<Sort />}
        >
          Sort
        </Button>
        <Button variant="outlined" startIcon={<Filter />}>
          Filter
        </Button>
      </Box>
    </Box>
  );
};

export default AttendanceComponent;
