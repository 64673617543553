import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import { Link } from "react-router-dom";
import LoginBackgroudImage from "../../Assets/images/authPages/imagerecovered.jpg";
const MainForgotDiv = styled("div")({
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${LoginBackgroudImage})`,
  backgroundRepeat: " no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  objectFit: "fill",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ForgotFormContainer = styled("div")(({ theme }) => ({
  //   backgroundColor: "white",
  background: "rgba(0, 0, 0, 0.1)",
  boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.3)",
  backdropFilter: " blur(1rem)",
  borderRadius: "2.5rem",
  padding: "5rem 1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  // width: "30%",
  // [theme.breakpoints.up("xs")]: {
  //   width: "80%",
  //   fontSize: ".7rem",
  //   flexDirection: "column",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: "50%",
  //   fontSize: ".8rem",
  //   textAlign: "center",
  // },

  // // Media Query for medium screens (between 601px and 960px)
  // [theme.breakpoints.up("md")]: {
  //   width: "40%",
  //   fontSize: ".9rem",
  // },

  // // Media Query for large and extra-large screens (min-width: 961px)
  // [theme.breakpoints.up("lg")]: {
  //   width: "30%",
  //   fontSize: ".9rem",
  // },
}));

const SubmitButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  color: black;

  &:hover {
    background-color: #fdfdfd;
    color: color;
  }
`;

const FormBoxContainer = styled(Box)({
  padding: "1rem",
});

const LinkButton = styled(Link)({
  textDecoration: "none",
  color: "white",
});

export {
  ForgotFormContainer,
  SubmitButton,
  FormBoxContainer,
  LinkButton,
  MainForgotDiv,
};
