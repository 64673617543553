import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import image from "../../demo.jpg";

const GeneralSettings = () => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "1rem" }}>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Profile Picture Upload
      </Typography>

      <Box display="flex" alignItems="center" mt={2}>
        <Avatar
          alt="User Avatar"
          src={image}
          sx={{ width: 100, height: 100 }}
        />
        <Box ml={2}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Faisal Haroon
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            <span>Admin</span>
          </Typography>
        </Box>

        <Box sx={{ marginLeft: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
          >
            Upload Profile Picture
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginLeft: "1rem" }}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Typography variant="h6" sx={{ fontWeight: 600, marginTop: "1rem" }}>
        Organizational Information
      </Typography>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="Organizational Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Email Address" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Address" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Phone Number" fullWidth />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontWeight: 600, marginTop: "1rem" }}>
        Address
      </Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="Organizational Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Email Address" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Address" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Phone Number" fullWidth />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Upload Profile Picture</DialogTitle>
        <DialogContent>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="profile-picture-upload"
            type="file"
          />
          <label htmlFor="profile-picture-upload">
            <Button
              variant="contained"
              component="span"
              color="primary"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Choose File
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GeneralSettings;
