import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDepartment: null,
};

const DepartmentsSlice = createSlice({
  name: "Departments",
  initialState,
  reducers: {
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
    },
    resetSelectedDepartment: (state) => {
      state.selectedDepartment = null;
    },
  },
});

export const { setSelectedDepartment ,resetSelectedDepartment} = DepartmentsSlice.actions;

export default DepartmentsSlice.reducer;
