import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TodayIcon from "@mui/icons-material/Today";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TagIcon from "@mui/icons-material/Tag";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import NoteIcon from "@mui/icons-material/Note";
import { Box } from "@mui/system";

const UserDetailsModal = ({ open, onClose, user }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Leave Details
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "none" }}>
        {user && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar
              alt=""
              src={`https://i.pravatar.cc/988?u=`}
              sx={{
                width: "120px",
                height: "120px",
                marginRight: "10px",
              }}
            />

            <div>
              <Typography variant="h6" fontWeight={600}>
                {user.name}
              </Typography>
            </div>
          </div>
        )}

        {user && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <ReceiptIcon sx={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">{user.type}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <TodayIcon sx={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">
                {new Date(user.from_date).toLocaleDateString("en-GB")}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <FactCheckIcon sx={{ marginRight: "10px" }} />
              {new Date(user.to_date).toLocaleDateString("en-GB")}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <TagIcon sx={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">{user.numberOfDays}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <InfoIcon sx={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">{user.status}</Typography>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <NoteIcon sx={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">{user.reason}</Typography>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserDetailsModal;
