import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventIcon from "@mui/icons-material/Event";
import TimerIcon from "@mui/icons-material/Timer";
import CheckIcon from "@mui/icons-material/Check";
import AverageTimeIcon from "@mui/icons-material/AccessTime";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import { theme } from "../../Theme";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import axios from "axios";

const EmployeeManagementTable = () => {
  const [todayAttendance, setTodayAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, 
    };
    const formattedTime = new Date(timeString).toLocaleTimeString("en-US", options);
    return formattedTime;
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem('employeeid'); 
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance/userAttendance/${userId}`;
        const response = await axios.get(apiUrl);

        if (response.status !== 200) {
          throw new Error(`Error: Received status code ${response.status}`);
        }

        const currentDate = new Date().toLocaleDateString();
        // const attendanceData = response.data.filter(
        //   (attendance) => new Date(attendance.record_time).toLocaleDateString() === currentDate
        // );
        setTodayAttendance(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(todayAttendance);

    XLSX.utils.book_append_sheet(wb, ws, "TodayAttendance");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "TodayAttendance.xlsx");
  };

  return (
    <Grid sx={{ width: "100%", marginInline: "auto", marginTop: "6rem" }}>
      <Box sx={{ width: "98%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5">
          All Attendance
        </Typography>
        <AppBar position="static" color="primary">
          <Toolbar sx={{ display: "flex", justifyContent: { xs: "end", sm: "space-between" } }}>
            <Typography
              variant="h6 "
              sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" } }}
            >
              Attendance
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextFieldStyled
                sx={{ marginLeft: "2rem" }}
                label="Search"
                variant="outlined"
                size="small"
                onChange={handleSearchChange}
                value={searchQuery}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ color: theme.palette.secondary.main }} />
                  ),
                }}
              />
              <Tooltip title="Export to Excel" arrow>
                <IconButton color="inherit" onClick={exportToExcel}>
                  <img src={svgExcel} alt="Excel" />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>

        <TableContainer sx={{ width: "100%", backgroundColor: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
              
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  <IconButton><CalendarMonthIcon /></IconButton>
                  Date
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  <IconButton><EventIcon /></IconButton>
                  Check-in
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  <IconButton><TimerIcon /></IconButton>
                  Break
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  <IconButton><CheckIcon /></IconButton>
                  Check-out
                </TableCell>
                <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                  <IconButton><AverageTimeIcon /></IconButton>
                  Average Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todayAttendance
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{formatDate(item.record_time)}</TableCell>
                    <TableCell align="center">{formatTime(item.record_time)}</TableCell>
                    <TableCell align="center">02:30:00</TableCell>
                    <TableCell align="center">07:00:00</TableCell>
                    <TableCell align="center">08:00:00</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={todayAttendance.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default EmployeeManagementTable;
