import React, { useEffect, useState } from "react";
import { upperContainer } from "./EmployeeDetailsStyle";
import {
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
} from
 
"@mui/material";
import { useDispatch, useSelector } from
 
"react-redux";
import { useNavigate } from
 
"react-router-dom";
import image from "../../demo.jpg";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PaginationCard from "./Pagination";
import * as XLSX from "xlsx";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import { theme } from "../../Theme";
import SelectSmall from "./DetailSelector";
import axios from "axios";

const EmployeeDetailsPage = () => {
  const selectedResponse = useSelector((state) => state.role.selectedResponse);
  const selectedEmployeeId = useSelector((state) => state.employees.selectedEmployeeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("selectedEmployeeId", selectedEmployeeId);
  }, [selectedEmployeeId]);

  useEffect(() => {
    return () => {
      // localStorage.removeItem("selectedEmployeeId");
    };
  }, []);

  const storedEmployeeId = localStorage.getItem("selectedEmployeeId");
  const initialEmployeeId = storedEmployeeId || selectedEmployeeId || null;

  const [employeeId, setEmployeeId] = useState(initialEmployeeId);
  const [role, setRole] = useState(selectedResponse?.role);
  const [data, setData] = useState("");
  const value = JSON.parse(localStorage.getItem("user_info"));

  useEffect(() => {
    if (value) {
      setRole(value.role);
    }
  setEmployeeId(localStorage.getItem("employeeid"))

  }, [value]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const EmployeeData = async () => {
      if (employeeId || value.id) {
        try {
          const url = `${process.env.REACT_APP_API_URL}/api/userinfo/${employeeId}`
          const response = await axios.get(
            url,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          setData(response?.data);
        } catch (error) {
          console.log("Error is occurring, check it out:", error);
        }
      }
    };
    EmployeeData();
  }, [employeeId, token]);

  const generateExcel = () => {
    const employeeData = [
      {
        Name: data.firstname + " " + data.lastname,
        Role: data.position,
        "Phone Number": data.phone_number,
        Email: data.email,
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(employeeData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Info");

    XLSX.writeFile(workbook, "employee_data.xlsx");
  };

  useEffect(() => {
    if (role !== "employee" && (storedEmployeeId === undefined || storedEmployeeId === null)) {
      // navigate("/all-employee");
    }

    return () => {
      // localStorage.removeItem("selectedEmployeeId");
    };
  }, [role, storedEmployeeId, navigate]);
  
  
  
  

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
          width: { md: "100%", xs: "1000px" },
        }}
      >
        {" "}
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
            {role === "admin" ? "Employee Details" : "My Profile"}
          </Typography>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            p={"1rem"}
            sx={upperContainer}
          >
            <Box
              width={"97%"}
              justifyContent={"center"}
              sx={{ marginInline: "auto" }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                color={theme.palette.primary.main}
              >
                <div style={{ display: "flex", alignitems: "center" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "40px",
                      background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                      marginRight: "10px",
                      borderRadius: "2.5rem",
                    }}
                  ></div>
                  <Typography variant="h4">
                    {" "}
                    {role === "admin" ? "Employee Details" : "My Profile"}
                  </Typography>
                </div>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <SelectSmall />
                  <Button
                    onClick={generateExcel}
                    variant="contained"
                    sx={{ marginLeft: ".5rem", padding: ".5rem 3rem" }}
                  >
                    DownLoad Info
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                mt={2}
                sx={{
                  width: "80%",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    xl: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "10rem", // Adjust size as needed
                    height: "10rem", // Adjust size as needed
                    borderRadius: "50%",
                    backgroundColor: "white",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image}
                    alt="user"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  flex={2}
                  p={2}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  color={theme.palette.primary.main}
                >
                  <Typography variant="h4">
                    {data.firstname + " " + data.lastname}
                  </Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography variant="h6">Role</Typography>
                      <Typography variant="span">{data.position}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Phone Number</Typography>
                      <Typography variant="span">
                        {data.phone_number}
                      </Typography>
                    </Box>{" "}
                    <Box>
                      <Typography variant="h6">Email</Typography>
                      <Typography variant="span ">{data.email}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                {[1, 2, 3, 4].map((element) => (
                  <Card
                    style={{
                      borderRadius: "1.5rem",
                      padding: ".5rem 2rem",
                      margin: ".3rem",
                      width: "24%",

                      background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                    }}
                  >
                    <CardActionArea>
                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <Box sx={{}}>
                          <DoubleArrowIcon />
                        </Box>
                        <Box style={{ flexGrow: 1, textAlign: "center" }}>
                          <Typography gutterBottom variant="h5" component="div">
                            309
                          </Typography>
                          <Typography variant="body2">
                            Total Attendance
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              borderRadius: "2rem",
              backgroundColor: "white",
              boxShadow: "0px 0px 7px grey",
              marginTop: "2rem",
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <PaginationCard />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default EmployeeDetailsPage;
