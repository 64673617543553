import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import { resetSelectedmanager } from "../../redux/Manager/managerSlice";
import TimePickerValue from "./EditTimePicker";
import TimePickerValue2 from "./EditTimePicker2";
import { useNavigate } from "react-router-dom";

const EditManager = () => {
  const [setValue, setSetValue] = useState("");
  const [getValue, setGetValue] = useState("");
  const navigate = useNavigate()
  const selectedDepartment = useSelector(
    (state) => state.department.selectedDepartment
  );
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/branch`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log(response, "ers");
      if (response.status === 200) {
        setCompanies(response.data);
      }
    } catch (error) {
      console.error("Async Function Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleCancel = () => {
    dispatch(resetSelectedmanager());
    formik.resetForm();
    navigate('/all-departments')
  };

  const formik = useFormik({
    initialValues: {
      name: selectedDepartment ? selectedDepartment.name : "",
      branch_id: selectedDepartment ? selectedDepartment.branch_id : "",
      starting_time: selectedDepartment ? selectedDepartment.starting_time : "",
      ending_time: selectedDepartment ? selectedDepartment.ending_time : "",
    },
    onSubmit: async (values) => {
      try {
        const departmentData = {
          name: values.name,
          branch_id: values.branch_id,
          starting_time: setValue || values.starting_time,
          ending_time: getValue || values.ending_time,
        };

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/departments/${selectedDepartment.id}`,
          departmentData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 200) {
          navigate("/all-departments");
          toast.success("Department Edit successful!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Async Function Error:", error);
      }
    },
  });

  return (
    <Grid
      container
      justifyContent={"center"}
      p={"1rem"}
      gap={5}
      width={"100vw"}
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
         Save
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Department
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <TextField
                  label="Department Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  required
                  sx={{
                    borderColor: "#1E4170",
                    marginBottom: "0.5rem",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Branch</InputLabel>
                  <Select
                    name="branch_id"
                    label="Branch"
                    value={formik.values.branch_id}
                    onChange={formik.handleChange}
                  >
                    {loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      companies.map((branch_id) => (
                        <MenuItem key={branch_id.id} value={branch_id.id}>
                          {branch_id.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <TimePickerValue
                  setSetValue={setSetValue}
                  checkValue="check In Time"
                  initialValue={selectedDepartment ? selectedDepartment.starting_time : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <TimePickerValue2
                  setGetValue={setGetValue}
                  checkValue="check Out Time"
                  initialValue={selectedDepartment ? selectedDepartment.ending_time : ""}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: "1rem",
              }}
            >
              SAVE
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default EditManager;
