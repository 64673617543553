import { TextField, styled } from "@mui/material";
import LoginBackgroudImage from "../../Assets/images/authPages/imagerecovered.jpg";

import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
// BackGround Styling
const MainSignUpDiv = styled("div")({
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${LoginBackgroudImage})`,
  backgroundRepeat: " no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  objectFit: "fill",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// Form Styling

const SignUpFormContainer = styled("div")(({ theme }) => ({
  //   backgroundColor: "white",
  background: "rgba(0, 0, 0, 0.1)",
  boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.3)",
  backdropFilter: " blur(1rem)",
  borderRadius: "2.5rem",
  padding: "5rem 1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  // width: "30%",
  // [theme.breakpoints.up("xs")]: {
  //   width: "80%",
  //   fontSize: ".7rem",
  //   flexDirection: "column",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: "50%",
  //   fontSize: ".8rem",
  //   textAlign: "center",
  // },

  // // Media Query for medium screens (between 601px and 960px)
  // [theme.breakpoints.up("md")]: {
  //   width: "40%",
  //   fontSize: ".9rem",
  // },

  // // Media Query for large and extra-large screens (min-width: 961px)
  // [theme.breakpoints.up("lg")]: {
  //   width: "30%",
  //   fontSize: ".9rem",
  // },
}));

const TextFieldStyled = styled(TextField)`
  label.Mui-focused {
    color: white; // Text color for the focused label
  }

  label.MuiInputLabel-root {
    color: white; // Text color for the label
  }

  .MuiInputBase-input {
    color: white; // Text color inside the input field
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: white; // Border color for the fieldset
    }

    &:hover fieldset {
      border-color: white; // Border color on hover
    }

    &.Mui-focused fieldset {
      border-color: white; // Border color when focused
    }
  }
`;

const SignInButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  color: black;

  &:hover {
    background-color: #fdfdfd;
    color: color;
  }
`;

const FormBoxContainer = styled(Box)({
  padding: "1rem",
});

const SignUpLinkButton = styled(Link)({
  textDecoration: "none",
  color: "white",
});

export {
  SignUpFormContainer,
  SignInButton,
  FormBoxContainer,
  SignUpLinkButton,
  MainSignUpDiv,
  TextFieldStyled,
};
