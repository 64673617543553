import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedResponse: null,
};

const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setSelectedResponse(state, action) {
      state.selectedResponse = action.payload;
    },
    resetSelectedResponse(state) {
      state.selectedResponse = null;
    },
  },
});

export const { setSelectedResponse, resetSelectedResponse } =
  responseSlice.actions;

export default responseSlice.reducer;
