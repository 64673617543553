import { styled } from "@mui/material";

export const upperContainer = {
  backgroundColor: "white",
  borderRadius: "2rem",
  width: "100%",
  boxShadow: "0px 0px 7px grey",
  color: "#44678A",
};

export const lowerContainer = {
  height: "60%",
};
export const EmployeeDetailContainer = styled("div")(({ theme }) => ({
  //   backgroundColor: "white",

  height: "100vh",
  display: "flex",

  alignItems: "center",
  flexDirection: "column",

  // width: "30%",
  // [theme.breakpoints.up("xs")]: {
  //   width: "80%",
  //   fontSize: ".7rem",
  //   flexDirection: "column",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: "50%",
  //   fontSize: ".8rem",
  //   textAlign: "center",
  // },

  // // Media Query for medium screens (between 601px and 960px)
  // [theme.breakpoints.up("md")]: {
  //   width: "40%",
  //   fontSize: ".9rem",
  // },

  // // Media Query for large and extra-large screens (min-width: 961px)
  // [theme.breakpoints.up("lg")]: {
  //   width: "30%",
  //   fontSize: ".9rem",
  // },
}));
