import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { theme } from "../../Theme";

const Graph = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "line",
      height: 380,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      // Initialize with empty categories, will be populated from API data
      categories: [],
      title: {
        text: 'Months',
      },
    },
    yaxis: {
      title: {
        text: "Days Attended",
      },
    },
    markers: {
      size: 5,
    },
    fill: {
      type: 'gradient',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " days";
        },
      },
    },
    colors: ["#64CD89"],
  });

  const [series, setSeries] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem('employeeid'); // Retrieve the user ID from local storage

    const fetchMonthlyAttendance = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dbAttendance/monthly-report/${userId}`);
    
        if (response.status !== 200) {
          throw new Error(`Error: Received status code ${response.status}`);
        }
    
        const monthlyAttendance = response.data;
        console.log("Fetched Data:", monthlyAttendance);
    
        // Process the data
        const attendanceData = [];
        const categories = [];
        monthlyAttendance.forEach(record => {
          const [month, year] = record.monthYear.split('-').map(Number);
          const totalDays = record.totalDays;
          attendanceData.push(totalDays);
          categories.push(`${getMonthName(month)}-${year}`);
        });
    
        console.log("Transformed Data:", attendanceData);
    
        // Update series and categories for the chart
        setSeries([{ name: "Days Attended", data: attendanceData }]);
        setOptions(prevOptions => ({
          ...prevOptions,
          xaxis: { ...prevOptions.xaxis, categories: categories }
        }));
      } catch (error) {
        console.error('Error fetching monthly attendance data:', error);
      }
    };
    
    fetchMonthlyAttendance();
  }, []);

  // Function to convert month number to month name
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "1rem",
        boxShadow: "0 10px 10px #0000004d",
      }}
    >
      <Typography mt={2} ml={2} p={3} sx={{ fontWeight: 600 }} variant="h5">
        Employee Monthly Attendance
      </Typography>
      <div id="chart">
        <ApexCharts options={options} series={series} type="line" height={445} />
      </div>
    </Box>
  );
};

export default Graph;
