import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PieChartIcon from "@mui/icons-material/PieChart";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import General from "./General";
import ProfileSettings from "./ProfileSettings";
import { theme } from "../../Theme";
import NotificationsSettings from "./NotificationsSetting";
import AdminProfileSettings from "./Accounts";

const tabData = [
  {
    label: "General",
    icon: <EventNoteIcon />,
    content: <General />,
  },
  {
    label: "Notifications",
    icon: <EditNotificationsIcon />,
    content: <NotificationsSettings />,
  },
  {
    label: "Account",
    icon: <ManageAccountsIcon />,
    content: <AdminProfileSettings />,
  },
];

const AdminSettings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      justifyContent="center"
      p="1rem"
      gap={5}
      sx={{
        width: "100%",
        margin: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", margin: "auto" }}>
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          Admin Settings
        </Typography>
        <Card
          sx={{
            display: "flex",
            borderRadius: "2rem",
            flexDirection: "row",
            boxShadow: "0 5px 15px #0000004d",
            height: "90vh",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            orientation="vertical"
            sx={{
              minWidth: "200px",

              "& .MuiTabs-indicator": {
                transition: "none",
                display: "none",
              },
            }}
          >
            {tabData.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  padding: "1rem 0",
                  marginTop: "1.3rem",
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span> {tab.icon} </span>
                    <Typography sx={{}} variant="body1">
                      {tab.label}
                    </Typography>
                  </Box>
                }
              />
            ))}
          </Tabs>

          <hr
            style={{
              height: "80vh",
              marginTop: "2.5rem",
              opacity: ".4",
            }}
          />

          <CardContent
            sx={{
              padding: "2rem",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: theme.palette.primary.main,
                marginBottom: "1rem",
              }}
            >
              {tabData[value].label}
            </Typography>
            <hr style={{ opacity: ".4" }} />
            {tabData[value].content}
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default AdminSettings;
