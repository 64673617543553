import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedManager: null,
  selectedManagerId: null, 
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    setSelectedManager(state, action) {
      state.selectedManager = action.payload;
      state.selectedManagerId = action.payload ? action.payload.employeeId : null;
    },
    resetSelectedmanager(state) {
      state.selectedManager = null;
      state.selectedManagerId = null;
    },
  },
});

export const { setSelectedManager, resetSelectedmanager } = managerSlice.actions;

export default managerSlice.reducer;
