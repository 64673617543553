import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DateCalendarServerRequest from "./DateCalendarValue";

const teamMembers = [
  {
    name: "John Doe",
    role: "Product Manager",
    status: "available",
    imageUrl: "image_url_1.jpg",
    email: "john@example.com",
  },
  {
    name: "Jane Smith",
    role: "Developer",
    status: "absent",
    imageUrl: "image_url_2.jpg",
    email: "jane@example.com",
  },
  // Add more team members here
];

const TeamCard = () => {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Card
      elevation={3}
      style={{
        height: "max-content",

        width: "100%",
        borderRadius: "1rem",
        boxShadow: " 0 10px 10px #0000004d",
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          My Team
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                <TableCell>{/* Empty cell */}</TableCell>
                <TableCell>
                  <Typography variant="h6">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Status</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
              {teamMembers.map((member, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar alt={member.name} src={member.imageUrl} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" style={{ color: "blue" }}>
                      {member.name}
                    </Typography>
                    <Typography variant="body2">{member.role}</Typography>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 80,
                        height: 30,
                        textAlign: "center",
                        color: member.status === "available" ? "green" : "red",
                        border: `2px solid ${
                          member.status === "available" ? "green" : "red"
                        }`,
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {member.status === "available" ? "Available" : "Absent"}
                    </div>
                  </TableCell>
                  <TableCell>
                    <EmailIcon
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => handleEmailClick(member.email)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DateCalendarServerRequest />
      </CardContent>
    </Card>
  );
};

export default TeamCard;
