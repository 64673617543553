import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { format, isValid } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast } from "react-toastify";

const EditLeaveRequestDialog = ({
  open,
  onClose,
  leaveRequestId,
  data,
  updateLeaveRequests,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [name, setName] = useState("");
  const [number_of_days, setnumber_of_days] = useState("");
  const [reason, setreason] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchLeaveTypes() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leaveType/`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log("Fetched leave types data:", response.data);
        setLeaveTypes(response.data);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    }
    fetchLeaveTypes();

    // Populate the form with existing data
      console.log(data,"new data");
      setSelectedLeaveType(data.type);

      // Check and format start date
      if (isValid(new Date(data.from_date))) {
        setSelectedStartDate(format(new Date(data.from_date), "yyyy-MM-dd"));
      } else {
        console.error("Invalid start date:", data.from_date);
        // Handle the case of invalid start date
      }

      // Check and format end date
      if (isValid(new Date(data.to_date))) {
        setSelectedEndDate(format(new Date(data.to_date), "yyyy-MM-dd"));
      } else {
        console.error("Invalid end date:", data.to_date);
        // Handle the case of invalid end date
      }

      setName(data.name);
      setnumber_of_days(data.number_of_days);
      setreason(data.reason);
  }, [token, data]);

  const handleLeaveTypeChange = (event) => {
    setSelectedLeaveType(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleEditLeave = async () => {
    if (!name || !selectedLeaveType || !number_of_days || !reason) {
      toast.error("Please fill in all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!selectedStartDate || !selectedEndDate) {
      toast.error("Please select valid start and end dates.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const leaveData = {
      name,
      from_date: format(new Date(selectedStartDate), "yyyy-MM-dd"),
      to_date: format(new Date(selectedEndDate), "yyyy-MM-dd"),
      number_of_days,
      type: selectedLeaveType,
      reason,
      employee_id: data.employee_id,
    };

    try {
      console.log(leaveRequestId, "leaveRequestId");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/leave/${leaveRequestId}`,
        leaveData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Leave updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        updateLeaveRequests(response.data.leave);
        onClose();
      } else {
        toast.error(`Failed to update leave: ${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error updating leave:", error);
      toast.error(
        "An error occurred while updating leave. Please try again later.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Leave Request
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={6}>
            <TextField
              id="name"
              label="Name"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="leaveType"
              label="Leave Type"
              fullWidth
              select
              required
              value={selectedLeaveType}
              onChange={handleLeaveTypeChange}
            >
              {leaveTypes.map((leaveTypeOption) => (
                <MenuItem
                  key={leaveTypeOption.id}
                  value={leaveTypeOption.leave_type}
                >
                  {leaveTypeOption.leave_type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleStartDateChange(e.target.value)}
              required
              value={selectedStartDate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleEndDateChange(e.target.value)}
              required
              value={selectedEndDate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="number_of_days"
              label="Number of Days"
              type="number"
              fullWidth
              required
              value={number_of_days}
              onChange={(e) => setnumber_of_days(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="reason"
              label="Reason"
              fullWidth
              multiline
              rows={4}
              required
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Button
                onClick={handleEditLeave}
                color="primary"
                variant="contained"
              >
                SAVE
              </Button>
              <Button
                onClick={onClose}
                color="error"
                variant="contained"
                sx={{ marginLeft: "1rem" }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditLeaveRequestDialog;
