import React from "react";
import { upperContainer } from "../EmployeeDetails/EmployeeDetailsStyle";
import {
  Card,
  Grid,
  Box,
  Typography,
  Button,
  CardContent,
  CardActionArea,
} from "@mui/material";
import image from "../../demo.jpg";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import PaginationCard from "../EmployeeDetails/Pagination";

import { theme } from "../../Theme";
import SelectSmall from "../EmployeeDetails/DetailSelector";
// import EmployeeDetailContainer /from "./EmployeeDetails/EmployeeDetailsStyle";

const HrDetailsPage = () => {
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        {" "}
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
            Department Details
          </Typography>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            p={"1rem"}
            sx={upperContainer}
          >
            <Box
              width={"97%"}
              justifyContent={"center"}
              sx={{ marginInline: "auto" }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                color={theme.palette.primary.main}
              >
                <div style={{ display: "flex", alignitems: "center" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "40px",
                      background:"linear-gradient(0.7turn, #55d7b3, #203341)",
                      marginRight: "10px",
                      borderRadius: "2.5rem",
                    }}
                  ></div>
                  <Typography variant="h4">Manager Details</Typography>
                </div>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <SelectSmall />
                  <Button
                    variant="contained"
                    sx={{ marginLeft: ".5rem", padding: ".5rem 3rem" }}
                  >
                    DownLoad Info
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                mt={2}
                sx={{
                  width: "80%",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    xl: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "10rem", 
                    height: "10rem", 
                    borderRadius: "50%",
                    backgroundColor: "white",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image} // Replace with the actual image URL
                    alt="user"
                    style={{
                      width: "100%", // Make the image fill the circle
                      height: "100%", // Make the image fill the circle
                      borderRadius: "50%", // Make the image circular
                      objectFit: "cover", // Ensure the image covers the entire circle
                    }}
                  />
                </Box>
                <Box
                  flex={2}
                  p={2}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  color={theme.palette.primary.main}
                >
                  <Typography variant="h4">Faisal Haroon</Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography variant="h6">Role</Typography>
                      <Typography variant="span">
                        Head of Human resources
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Phone Number</Typography>
                      <Typography variant="span">0000-000000-0</Typography>
                    </Box>{" "}
                    <Box>
                      <Typography variant="h6">Email</Typography>
                      <Typography variant="span ">
                        FaisalHaronn500@gmail.com
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                mt={4}
              >
                {[1, 2, 3, 4].map((element) => (
                  <Card
                    style={{
                      borderRadius: "1.5rem",
                      padding: ".5rem 2rem",
                      margin: ".3rem",
                      width: "24%",

                      background: "linear-gradient(0.7turn, #55d7b3, #203341)",
                    }}
                  >
                    <CardActionArea>
                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <Box sx={{}}>
                          <DoubleArrowIcon />
                        </Box>
                        <Box style={{ flexGrow: 1, textAlign: "center" }}>
                          <Typography gutterBottom variant="h5" component="div">
                            309
                          </Typography>
                          <Typography variant="body2">
                            Total Attendance
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              borderRadius: "2rem",
              backgroundColor: "white",
              boxShadow: "0px 0px 7px grey",
              marginTop: "2rem",
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <PaginationCard />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default HrDetailsPage;
