import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { theme } from "../../Theme";

import AddLeaveRequestDialog from "../LeaveManagement/ModalForEmployeeLeave";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import SearchIcon from "@mui/icons-material/Search";
import EditLeaveRequestDialog from "../LeaveManagement/ModalForEmployeeEditLeave";

const EmployeeLeavePage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [newLeaveRequestId, setNewLeaveRequestId] = useState(null);
  const [data, setData] = useState({});
  const [createLeave, setCreateLeave] = useState(
    `${process.env.REACT_APP_API_URL}/api/leave/`
  );

  const [isAddLeaveDialogOpen, setAddLeaveDialogOpen] = useState(false);
  const [isEditLeaveDialogOpen, setEditLeaveDialogOpen] = useState(false);

  const [newId, setNewId] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const employeeId = localStorage.getItem("employeeid");
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/leave/${employeeId}`;
    setNewId(employeeId);

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const leaveRequestsData = response.data;
        console.log("API Response:", leaveRequestsData);
        setLeaveRequests(leaveRequestsData);
      })
      .catch((error) => {
        console.error("Error fetching leave requests:", error);
      });
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const updateLeaveRequests = (editedLeaveRequest) => {
    setLeaveRequests((prevLeaveRequests) => {
      const updatedLeaveRequests = prevLeaveRequests.map((request) =>
        request.id === editedLeaveRequest.id ? editedLeaveRequest : request
      );

      return updatedLeaveRequests;
    });
  };
  const updateLeaveRequests1 = (newLeaveRequest) => {
    setLeaveRequests((prevLeaveRequests) => [
      ...prevLeaveRequests,
      newLeaveRequest.leave,
    ]);
  };
  
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteClick = (leaveRequestId) => {
    setCurrentRequest(leaveRequestId);
    setIsDeleteModalOpen(true);
  };

  const filteredLeaveRequests = leaveRequests.filter((request) => {
    const nameMatches =
      request.name.toLowerCase().includes(searchQuery.toLowerCase()) || false;
    const reasonMatches =
      request.reason.toLowerCase().includes(searchQuery.toLowerCase()) || false;
    const fromDateMatches =
      (request.fromDate || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
      false;
    const toDateMatches =
      (request.toDate || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
      false;
    const statusMatches =
      (request.status || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
      false;

    return (
      nameMatches ||
      reasonMatches ||
      fromDateMatches ||
      toDateMatches ||
      statusMatches
    );
  });

  const handleDeleteConfirm = async (leaveRequestId) => {
    setIsDeleteModalOpen(false);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/leave/${leaveRequestId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      setLeaveRequests((prevLeaveRequests) =>
        prevLeaveRequests.filter((request) => request.id !== leaveRequestId)
      );

      toast.success("Leave deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error deleting leave request:", error);
      toast.error("Failed to delete leave request.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const openAddLeaveDialog = (data) => {
    setAddLeaveDialogOpen(true);
    // If data is provided, set the data state
    if (data && data.id) {
      setNewLeaveRequestId(data.id);
      setData(data);
    } else {
      // If no data is provided, reset the data state
      setNewLeaveRequestId(null);
      setData({});
    }
  };
  
  

  

  const closeAddLeaveDialog = () => {
    setAddLeaveDialogOpen(false);
  };

  const openEditLeaveDialog = (leaveRequestId, data) => {
    console.log("Click on edit")
    setNewLeaveRequestId(leaveRequestId);
    setData(data);
    setEditLeaveDialogOpen(true);
  };

  const closeEditLeaveDialog = () => {
    setEditLeaveDialogOpen(false);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        p="1rem"
        gap={5}
        width="100vw"
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5">All Leave Requests</Typography>
          <Paper sx={{ marginTop: "2rem" }}>
            <AppBar position="static">
              <Toolbar sx={{ display: "flex", justifyContent: { xs: "end" } }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  Leave Requests
                </Typography>
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />
                <IconButton color="inherit" onClick={() => openAddLeaveDialog()}>
                  <Tooltip title="Add new Leave" arrow>
                    <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      Reason for Leaves
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Leave From</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Leave To</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Number of Days
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredLeaveRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                              alt={request.name}
                              src={`https://i.pravatar.cc/40?u=${request.id}`}
                              sx={{ marginRight: 1 }}
                            />
                            {request.name}
                          </Box>
                        </TableCell>
                        <TableCell>{request.reason}</TableCell>
                        <TableCell>{formatDate(request.from_date)}</TableCell>
                        <TableCell>{formatDate(request.to_date)}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {request.number_of_days}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: 80,
                              height: 30,
                              textAlign: "center",
                              color:
                                request.status === "Accepted" ? "green" : "red",
                              border: `2px solid ${
                                request.status === "Accepted" ? "green" : "red"
                              }`,
                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {request.status}
                          </div>
                        </TableCell>

                        <TableCell>
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              onClick={() =>
                                openEditLeaveDialog(request.id, request)
                              }
                            >
                              <EditIcon
                                sx={{ color: theme.palette.editIcon.main }}
                              />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            key={request.id}
                            onClick={() => handleDeleteClick(request.id)}
                          >
                            <Tooltip title="Delete" arrow>
                              <DeleteOutlineIcon
                                sx={{ color: theme.palette.deleteIcon.main }}
                              />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredLeaveRequests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Paper>
        </Box>
        <AddLeaveRequestDialog
          open={isAddLeaveDialogOpen}
          onClose={closeAddLeaveDialog}
          createLeave={createLeave}
          newId={newId}
          updateLeaveRequests={updateLeaveRequests1}
        />
        <EditLeaveRequestDialog
          open={isEditLeaveDialogOpen}
          onClose={closeEditLeaveDialog}
          leaveRequestId={newLeaveRequestId}
          data={data}
          updateLeaveRequests={updateLeaveRequests}
        />
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteConfirm(currentRequest)}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeLeavePage;
