import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import UserDetailsModal from "./UserModal";
// import AddLeaveRequestDialog from "./ModalForEmployeeLeave";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { theme } from "../../Theme";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import SearchIcon from "@mui/icons-material/Search";
import EditLeaveRequestDialog from "./ModalForEmployeeEditLeave";
import { toast } from "react-toastify";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import AcceptRejectDialog from "./StatusModal";

const LeaveRequestTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);

  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [newStartDate, setNewStartDate] = useState("");
  // const [newEndDate, setNewEndDate] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isAddLeaveDialogOpen, setAddLeaveDialogOpen] = useState(false);
  const [newLeaveRequestId, setNewLeaveRequestId] = useState(null);
  const [data, setData] = useState({});
  const [update, setUpdate] = useState(null);
  const [currentRequest, setCurrentrequest] = useState(null);
  const [isAcceptRejectModalOpen, setAcceptRejectModalOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const token = localStorage.getItem("token");

  // const openAddLeaveDialog = () => {
  //   setIsAddLeaveDialogOpen(true);
  // };

  // const closeAddLeaveDialog = () => {
  //   setIsAddLeaveDialogOpen(false);
  // };

  const [leaveRequests, setLeaveRequests] = useState([]);
  useEffect(() => {
    const user_info = JSON.parse(localStorage.getItem("user_info"));
    console.log(user_info.department_id,"department_id")
    if (!user_info.department_id) {
      console.error("department_id not found in local storage");
      return;
    }
    
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/leave/departmentLeaves/${user_info.department_id}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const leaveRequestsData = response.data;
        console.log("API Response:", leaveRequestsData);
        setLeaveRequests(leaveRequestsData);
      })
      .catch((error) => {
        console.error("Error fetching leave requests:", error);
      });
  }, []);

  const handleDetailsClick = (employeeId) => {
    console.log(employeeId, "employeeId");
    const specificRequest = leaveRequests.find(
      (request) => request.id === employeeId
    );

    if (specificRequest) {
      console.log("Specific Request Data:", specificRequest);
      setSelectedUser(specificRequest);
      setIsUserDetailsModalOpen(true);
    } else {
      console.log("Request not found for employeeId:", employeeId);
    }
  };
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (leaveRequestId) => {
    setCurrentrequest(leaveRequestId);
    setIsDeleteModalOpen(true);
    console.log("My current leave", currentRequest);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleteModalOpen(false);
    const id = currentRequest;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/leave/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      console.log("Leave deleted successfully!");
      toast.success("Leave request deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLeaveRequests((prevData) =>
        prevData.filter((name) => name.id !== id)
      );
    } catch (error) {
      console.error("Error deleting request:", error);
    }
  };

  const handleOpenAcceptRejectModal = (LeaveId) => {
    setAcceptRejectModalOpen(true);
    setSelectedLeaveId(LeaveId);
  };
  console.log(
    "this is the id which want to change the status",
    selectedLeaveId
  );
  const handleCloseAcceptRejectModal = () => {
    setAcceptRejectModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const updateLeaveRequests = (response) => {
    setLeaveRequests((prevLeaveRequests) => [...prevLeaveRequests, response]);
    setUpdate(response);
  };

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // }

  const openAddLeaveDialog = (data) => {
    setAddLeaveDialogOpen(true);
    setNewLeaveRequestId(data.id);
    setData(data);
  };

  const closeAddLeaveDialog = () => {
    setAddLeaveDialogOpen(false);
  };
  const updateStatusInUI = (userLeaveId, newStatus) => {
    // Update the status for the leave request with the specified UserLeaveId
    setLeaveRequests((prevLeaveRequests) =>
      prevLeaveRequests.map((request) =>
        request.id === userLeaveId
          ? { ...request, status: newStatus }
          : request
      )
    );
  };

  const filteredLeaveRequests = leaveRequests.filter(
    (request) =>
      request.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.reason?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.fromDate?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.toDate?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      false
  );
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(leaveRequests);

    XLSX.utils.book_append_sheet(wb, ws, "LeaveRequests");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "LeaveRequests.xlsx");
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Paper sx={{ marginTop: "2rem" }}>
            <AppBar position="static">
              <Toolbar sx={{ display: "flex", justifyContent: { xs: "end" } }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  All Leave
                </Typography>

                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />
                {/* <IconButton color="inherit" onClick={openAddLeaveDialog}>
                <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
              </IconButton> */}

                <IconButton color="inherit" onClick={exportToExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: 600 }}>
                      Reason for Leave
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Leave From</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Leave To</TableCell>
                    <TableCell sx={{ textAlign: "center", fontWeight: 600 }}>
                      Number of Days
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredLeaveRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>
                          <Avatar
                            alt={request.name}
                            src={`https://i.pravatar.cc/40?u=${request.id}`}
                            sx={{ marginLeft: 2 }}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetailsClick(request.id)}
                        >
                          {request.name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {request.reason}
                        </TableCell>
                        <TableCell>
                          {new Date(request.from_date).toLocaleDateString(
                            "en-GB"
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(request.to_date).toLocaleDateString("en-GB")}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {request.number_of_days}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            handleOpenAcceptRejectModal(request.id)
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          <div
                            style={{
                              width: 80,
                              height: 30,
                              textAlign: "center",
                              color:
                                request.status === "Accepted"
                                  ? "green"
                                  : request.status === "pending"
                                  ? theme.palette.primary.main
                                  : "red",
                              border: `2px solid ${
                                request.status === "Accepted"
                                  ? "green"
                                  : request.status === "pending"
                                  ? theme.palette.primary.main
                                  : "red"
                              }`,

                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {request.status}
                          </div>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            onClick={() => openAddLeaveDialog(request)}
                          >
                            <EditIcon
                              sx={{ color: theme.palette.editIcon.main }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(request.id)}
                          >
                            <DeleteOutlineIcon
                              sx={{ color: theme.palette.deleteIcon.main }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDetailsClick(request.id)}
                          >
                            <RemoveRedEyeIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                count={leaveRequests.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              />
            </TableContainer>
          </Paper>
          <UserDetailsModal
            open={isUserDetailsModalOpen}
            onClose={() => setIsUserDetailsModalOpen(false)}
            user={selectedUser}
          />
        </Box>

        <EditLeaveRequestDialog
          open={isAddLeaveDialogOpen}
          onClose={closeAddLeaveDialog}
          leaveRequestId={newLeaveRequestId}
          data={data}
          updateLeaveRequests={updateLeaveRequests}
        />
        <AcceptRejectDialog
          open={isAcceptRejectModalOpen}
          onClose={handleCloseAcceptRejectModal}
          UserLeaveId={selectedLeaveId}
          updateStatusInUI={updateStatusInUI}
        />
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* Accept or reject modal */}
    </>
  );
};

export default LeaveRequestTable;
