import React from "react";
import { Paper, Typography, TextField, Grid, Button, Box } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import { resetSelectedCompany } from "../../redux/companies/companySlice";
import { useNavigate } from "react-router-dom";

const EditCompany = () => {
  const selectedBranch = useSelector((state) => state.company.selectedCompany);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleCancel = () => {
    formik.resetForm({ values: {
      company_id: selectedBranch ? selectedBranch.company_id : 1,
      name: selectedBranch ? selectedBranch.name : "",
      phone_number: selectedBranch ? selectedBranch.phone_number : "",
      address: selectedBranch ? selectedBranch.address : "",
      email: selectedBranch ? selectedBranch.email : "",
      website: selectedBranch ? selectedBranch.website : "",
    }});

    dispatch(resetSelectedCompany());
    navigate("/all-companies");
  };

  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      company_id: selectedBranch ? selectedBranch.company_id : 1,
      name: selectedBranch ? selectedBranch.name : "",
      phone_number: selectedBranch ? selectedBranch.phone_number : "",
      address: selectedBranch ? selectedBranch.address : "",
      email: selectedBranch ? selectedBranch.email : "",
      website: selectedBranch ? selectedBranch.website : "",
    },
    onSubmit: async (values) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/branch/${selectedBranch.id}`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.status === 200) {
          navigate("/all-companies")
          toast.success("Branch edited successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Failed to edit branch.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("Async Function Error:", error);

        if (error.response) {
          console.error("Response Data:", error.response.data);
          console.error("Response Status:", error.response.status);
        }

        toast.error("An error occurred while editing the branch.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  return (
    <Grid
      container
      justifyContent="center"
      p="1rem"
      gap={5}
      width="100vw"
      sx={{
        marginInline: "auto",
        marginTop: "5rem",
      }}
    >
      <Box sx={{ width: "100%", marginInline: "auto" }}>
        <Typography sx={{ marginBottom: "2rem" }} variant="h5" gutterBottom>
          Edit Branch
        </Typography>
        <Paper
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            backgroundColor: "#f0f0f0",
            boxShadow: " 0 10px 10px #0000004d",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Branch Details
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Branch Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Branch Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="phone_number"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Website"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: "1rem", backgroundColor: "#1E4170" }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default EditCompany;
