import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import DashboardCard from "./DashboardCard";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AttributionIcon from "@mui/icons-material/Attribution";
import EmployeeGraph from "./EmployeeGraph";
import TeamCard from "./TeamCard";
const EmployeeDashoboard = () => {
  // Dummy data
  const employeeData = [
    {
      number: 75,
      title: "Total Attendance",
      icon: <EventNoteIcon fontSize="large" />,
      background: "linear-gradient(to right,#664dc9,#9884ea)",
    },
    {
      number: "12:00",
      title: "Average Check-In Time ",
      icon: <WhereToVoteIcon fontSize="large" />,
      background: "linear-gradient(to right,#1d976c,#2fd38a)",
    },
    {
      number: "12:00",
      title: "Total  Check-out Time",
      icon: <DirectionsWalkIcon fontSize="large" />,
      background: "linear-gradient(to right,#fa5420,#f6a800)",
    },
    {
      number: "----",
      title: "Role Model",
      icon: <AttributionIcon fontSize="large" />,
      background: "linear-gradient(to right,#5b73e8,#44c4fa)",
    },
  ];
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        {" "}
        <Box
          sx={{
            width: "99%",
            marginInline: "auto",
            display: "flex",
            justifyContent: { xs: "center", xl: "start" },
          }}
        >
          <Typography variant="h5">Dashboard</Typography>
        </Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                margin: "auto",
                display: "flex",

                flexDirection: {
                  xl: "row",
                  xs: "column",
                  sm: "row",
                  md: "row",
                },
                marginTop: "1rem",
              }}
            >
              {employeeData.map((newData) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <DashboardCard {...newData} />
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              xs: "column",
              sm: "column",
              md: "row",
            },
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box
              sx={{
                width: "100%",
                marginTop: "1rem",
                padding: ".5rem",
              }}
            >
              <EmployeeGraph />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            mt={1}
            display="flex"
            sx={{}}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                marginTop: "1rem",
              }}
            >
              <TeamCard />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default EmployeeDashoboard;
