import React from "react";
import { MainSignUpDiv } from "./SignUpStyle";
import SignUp from "./SignUp";
import { Grid } from "@mui/material";

const SignUpForm = () => {
  return (
    <MainSignUpDiv>
      <Grid container>
        <Grid container justifyContent={"center"}>
          {<SignUp></SignUp>}
        </Grid>
      </Grid>
    </MainSignUpDiv>
  );
};

export default SignUpForm;
