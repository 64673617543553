import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideNav from "../Components/Dashboard/SideNav";

const ProtectedRoutes = () => {
  // const isAuth = localStorage.getItem("user_info");
  const isAuth = true;

  return isAuth ? (
    <>
      <SideNav />
      <Outlet />
    </>
  ) : (
    <Navigate to="login" />
  );
};

export default ProtectedRoutes;
