import React, { useState, useEffect } from "react";
import {
  Grid, Box, Typography, TextField, Button, MenuItem,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Card
} from "@mui/material";
import {
  CheckCircleOutline, HighlightOff, WbSunny
} from "@mui/icons-material";
import axios from "axios";

const AttendanceSheet = () => {
  const [todayAttendance, setTodayAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allAttendance, setAllAttendance] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const isWeekend = (day) => {
    return day === 0 || day === 6; // 0 represents Sunday, and 6 represents Saturday
  };

  const filterFirstLoginPerUserPerDate = (attendanceData) => {
    const sortedData = attendanceData.sort(
      (a, b) => new Date(a.record_time) - new Date(b.record_time)
    );

    const processedNames = {};
    return sortedData.filter((attendance) => {
      const recordDate = new Date(attendance.record_time).toISOString().split("T")[0];
      const name = attendance.user_info.name;
      if (!processedNames[`${recordDate}-${name}`]) {
        processedNames[`${recordDate}-${name}`] = true;
        return true;
      }
      return false;
    });
  };

  const renderAttendanceCells = (employeeName) => {
    const attendanceMap = new Map(
      todayAttendance
        .filter((attendance) => attendance.user_info.name === employeeName)
        .map((attendance) => [
          new Date(attendance.record_time).getDate(),
          attendance,
        ])
    );

    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const cells = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const attendance = attendanceMap.get(i);
      const dayOfWeek = new Date(selectedYear, selectedMonth, i).getDay();
      const date = new Date(selectedYear, selectedMonth, i);

      cells.push(
        <TableCell key={i}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.2rem",
              fontSize: "11px",
              color: attendance && !isWeekend(dayOfWeek) ? "green" : "red",
            }}
          >
            {attendance ? (
              <CheckCircleOutline />
            ) : new Date() > date ? (
              <HighlightOff />
            ) : (
              "Upcoming"
            )}
            {isWeekend(dayOfWeek) && "Holiday"}
            {!isWeekend(dayOfWeek) && (i + 1) % 7 === 0 && (
              <WbSunny sx={{ color: "yellow" }} />
            )}
          </Box>
        </TableCell>
      );
    }
    return cells;
  };

  const renderTableRows = () => {
    const processedNames = {};
    return todayAttendance.map((attendance) => {
      const employeeName = attendance.user_info.name;
      if (!processedNames[employeeName]) {
        processedNames[employeeName] = true;
        return (
          <TableRow key={employeeName}>
            <TableCell>{employeeName}</TableCell>
            {renderAttendanceCells(employeeName)}
          </TableRow>
        );
      }
      return null;
    });
  };

  const renderTableHeaders = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const headers = [];
    for (let i = 1; i <= daysInMonth; i++) {
      headers.push(<TableCell key={i}>{i}</TableCell>);
    }
    return headers;
  };

  const fetchData = async () => {
    const user_info = JSON.parse(localStorage.getItem("user_info"));
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/dbAttendance`;
    try {
      setLoading(true);
      const response = await axios.get(apiUrl);
      setAllAttendance(response.data);
      const filteredData = filterFirstLoginPerUserPerDate(response.data);
      setTodayAttendance(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data on component mount

  const handleMonthYearChange = () => {
    const filteredData = filterFirstLoginPerUserPerDate(
      allAttendance.filter((attendance) => {
        const recordDate = new Date(attendance.record_time);
        return recordDate.getMonth() === selectedMonth &&
          recordDate.getFullYear() === selectedYear;
      })
    );
    setTodayAttendance(filteredData);
  };

  const filterAttendanceData = () => {
    const filteredData = allAttendance.filter((attendance) => {
      const recordDate = new Date(attendance.record_time);
      return (
        recordDate.getMonth() === selectedMonth &&
        recordDate.getFullYear() === selectedYear
      );
    });

    return filterFirstLoginPerUserPerDate(filteredData);
  };

  useEffect(() => {
    const filteredData = filterAttendanceData();
    setTodayAttendance(filteredData);
  }, [selectedMonth, selectedYear]);



  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: "6rem", flexGrow: 1 }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            width: {
              xs: "400px",
              sm: "500px",
              md: "700px",
              lg: "900px",
              xl: "1300px",
            },
            margin: "30px",
          }}
        >
          <Typography variant="h5">Attendance Sheet</Typography>
          <Card
            elevation={3}
            sx={{ padding: "1rem", width: "100%", marginTop: "2rem" }}
          >
            <Typography variant="h4">Attendance Sheet</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <TextField
                  select
                  label="Month"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  helperText="Select Month"
                >
                  {Array.from({ length: 12 }, (_, index) => (
                    <MenuItem key={index} value={index}>
                      {new Date(0, index).toLocaleString("default", {
                        month: "long",
                      })}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Year"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  helperText="Select Year"
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <MenuItem
                      key={index}
                      value={new Date().getFullYear() - index}
                    >
                      {new Date().getFullYear() - index}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
             
            </Box>
            <Box
              sx={{ maxWidth: "100%", overflowX: "auto", marginTop: "2rem" }}
            >
              <TableContainer sx={{ maxWidth: "100%" }}>
                <Table
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    width: "auto",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Employee</TableCell>
                      {renderTableHeaders()}
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableRows()}</TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AttendanceSheet;
