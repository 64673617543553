import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const EditHolidayDialog = ({ open, onClose, holiday,updateHoliday }) => {
  const [holidays_name, setName] = useState("");
  const [holidays_date, setHolidaysDate] = useState("");
  const [holidays_location, setholidays_location] = useState("");
  const [shift, setShift] = useState("");

  useEffect(() => {
    if (holiday) {
      console.log("Holiday in useEffect:", holiday);
      setName(holiday.holidays_name);
      const formattedDate = new Date(holiday.holidays_date).toISOString().split('T')[0];
      setHolidaysDate(formattedDate);
      setholidays_location(holiday.holidays_location);
      setShift(holiday.shift);
    }
  }, [holiday]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleholidays_dateChange = (event) => {
    setHolidaysDate(event.target.value);
  };

  const handleholidays_locationChange = (event) => {
    setholidays_location(event.target.value);
  };

  const handleshiftChange = (event) => {
    setShift(event.target.value);
  };

  const handleEditHoliday = async () => {
    try {
      if (!holidays_name || !holidays_date || !holidays_location || !shift) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const editedHoliday = {
        holidays_number: holiday.holidays_number,
        holidays_name,
        holidays_date,
        holidays_location,
        shift,
        details: holiday.details,
      };

      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/holiday/${holiday.id}`,
        editedHoliday,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      console.log("Response from server:", response);

      if (response.status === 200) {
        toast.success("Holiday edited successfully!");
        updateHoliday(editedHoliday);
        onClose();
      } else {
        toast.error("Failed to edit holiday. Please try again later.");
      }
    } catch (error) {
      console.error("Error editing holiday:", error);
      toast.error("An error occurred while editing holiday. Please try again later.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Holiday
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Name"
              fullWidth
              required
              value={holidays_name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="holidays_date"
              label="holidays_date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              required
              value={holidays_date || ''}
              onChange={handleholidays_dateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="holidays_location"
              label="holidays_location"
              fullWidth
              required
              value={holidays_location}
              onChange={handleholidays_locationChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>shift</InputLabel>
              <Select
                value={shift}
                onChange={handleshiftChange}
                label="shift"
                required
              >
                <MenuItem value="morning">Morning</MenuItem>
                <MenuItem value="afternoon">Afternoon</MenuItem>
                <MenuItem value="evening">Evening</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" alignItems="center">
              <Button
                onClick={handleEditHoliday}
                color="primary"
                variant="contained"
              >
                Save Changes
              </Button>
              <Button
                onClick={onClose}
                color="error"
                variant="contained"
                sx={{ marginLeft: "1rem" }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditHolidayDialog;
