import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography, Paper, Button } from "@mui/material";
import axios from "axios";

const Backlog = () => {
  const [backlogItems, setBacklogItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(backlogItems.length / itemsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/dbAttendance/calculatePendingHoursAndOvertime`
        );
        setBacklogItems(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const formatPendingHours = (hours) => {
    const totalMinutes = Math.round(hours * 60);
    const hr = Math.floor(totalMinutes / 60);
    const min = totalMinutes % 60;
    return `${hr} hr ${min} mins`;
  };

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = backlogItems.slice(firstItemIndex, lastItemIndex);

  const goToNextPage = () => setCurrentPage(currentPage + 1);
  const goToPrevPage = () => setCurrentPage(currentPage - 1);

  return (
    <Paper
      elevation={3}
      sx={{
        width: "100%",
        height: "380px",
        boxShadow: "0 10px 10px #0000004d",
        borderRadius: "1rem",
        margin: "2rem 0",
        padding: "1rem",
        position: "relative",
        "& .table-heading": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "20px", // Adjusted font size
          fontWeight: "bold",
          marginBottom: "1rem",
        },
        "& .custom-table": {
          width: "100%",
          borderCollapse: "collapse",
          fontSize: "14px", // Adjusted font size
        },
        "& .custom-table th, & .custom-table td": {
          padding: "10px",
          textAlign: "left",
        },
        "& .custom-table th": {
          backgroundColor: "#f7f7f7",
          color: "#505050",
          fontWeight: "bold",
          padding: "12px 10px", // Adjusted padding
          position: "relative",
          "& .icon": {
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
          },
        },
        "& .numeric-cell": {
          textAlign: "center",
        },
        "& .employee-name": {
          fontWeight: "bold",
          fontSize: "16px", // Adjusted font size
          marginBottom: "5px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
        Work Hours Summary
        </Typography>
      </Box>
      <table className="custom-table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Days</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td>
                <Avatar
                  alt={item.name}
                  src={`https://i.pravatar.cc/40?u=${index}`}
                />
              </td>
              <td>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#505050",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {item.position}
                </Typography>
              </td>
              <td className="numeric-cell">{item.totalDays}</td>
              <td className="numeric-cell">
                {formatPendingHours(item.pendingHours)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <Button
      onClick={goToPrevPage}
      disabled={currentPage === 1}
      sx={{ margin: '0 10px' }}
    >
      {'<'}
    </Button>

    {[...Array(totalPages).keys()].map(number => (
      <Button
        key={number + 1}
        onClick={() => setCurrentPage(number + 1)}
        sx={{ margin: '0 10px' }}
      >
        {number + 1}
      </Button>
    ))}

    <Button
      onClick={goToNextPage}
      disabled={currentPage === totalPages}
      sx={{ margin: '0 10px' }}
    >
      {'>'}
    </Button>
  </Box>

    </Paper>
  );
};

export default Backlog;
