import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Radio,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import svgExcel from "../../Assets/images/authPages/excelicon.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { theme } from "../../Theme";
import { TextFieldStyled } from "../LoginForm/LoginFormStyle";
import axios from "axios";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedManager } from "../../redux/Manager/managerSlice";
import { toast } from "react-toastify";

const StudentManagementTable = () => {
  const [managerData, setManagerData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedManager, setSelectedManagerState] = useState(null);
  const [currentManager, setCurrentManager] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchManagerData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/userinfo`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        // Filter managers with role "hr"
        const managerDataWithRole = response?.data.filter(
          (manager) => manager.role === "hr"
        );

        setManagerData(managerDataWithRole);
      } catch (error) {
        console.error("Error fetching manager data:", error);
      }
    }

    fetchManagerData();
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleRadioChange = (studentId) => {
    setSelectedStudent(studentId);
  };

  const NavigatingPage = () => {
    navigate("/add-hr");
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const detailPage = (employeeId) => {
    setSelectedRow(employeeId);

    localStorage.setItem("selectedEmployeeId", employeeId);
    navigate("/employee-detail");

    const selectedEmployee = managerData?.find(
      (employee) => employee.id === employeeId
    );

    if (selectedEmployee) {
      setSelectedManagerState(selectedEmployee);
      console.log("Manager ID:", employeeId);
      console.log("Selected Manager:", selectedEmployee);

      dispatch(setSelectedManager(selectedEmployee));
    } else {
      console.error("Employee not found with ID:", employeeId);
    }
  };

  // const handleCheckboxChange = (studentId) => {
  //   setSelectedStudents((prevSelected) =>
  //     prevSelected.includes(studentId)
  //       ? prevSelected.filter((id) => id !== studentId)
  //       : [...prevSelected, studentId]
  //   );
  // };

  const handleEditClick = (employee) => {
    // Dispatch the selected manager object
    dispatch(setSelectedManager(employee));
    navigate("/edit-hr");
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteClick = (employee) => {
    setCurrentManager(employee);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = async () => {
    const id = currentManager.id;
    setIsDeleteModalOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/userinfo/${id}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      console.log("HR Manager deleted successfully!");
      toast.success("HR Manager deleted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setManagerData((prevData) => prevData.filter((emp) => emp.id !== id));
    } catch (error) {
      console.error("Error deleting Department:", error);
    }
  };
  const generateExcel = () => {
    const workbook = XLSX.utils.book_new();
    const data = managerData;

    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "HR Managers");

    XLSX.writeFile(workbook, "hr_managers_data.xlsx");
  };

  const filteredEmployeeData = managerData.filter((employee) =>
    employee.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        p={"1rem"}
        gap={5}
        width={"100vw"}
        sx={{
          marginInline: "auto",
          marginTop: "5rem",
        }}
      >
        <Box sx={{ width: "100%", marginInline: "auto" }}>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            All HR Managers{" "}
          </Typography>
          <Paper sx={{ marginTop: "2rem" }}>
            <AppBar position="static" color="primary">
              <Toolbar sx={{ display: "flex", justifyContent: { xs: "end" } }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  All HR Managers
                </Typography>
                <TextFieldStyled
                  label="Search"
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    ),
                  }}
                />
                <IconButton color="inherit" onClick={NavigatingPage}>
                  <Tooltip title="Add new Manager" arrow>
                    <AddCircleIcon sx={{ fontSize: "2.5rem" }} />
                  </Tooltip>
                </IconButton>
                <IconButton color="inherit" onClick={generateExcel}>
                  <Tooltip title="Export to Excel" arrow>
                    <img src={svgExcel} />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>

                    <TableCell sx={{ fontWeight: 600 }}>Role</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Degree</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      Mobile Number
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Joining Date</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {filteredStudents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {filteredEmployeeData?.map((employee) => (
                    <TableRow
                      key={employee.id}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          employee.id === selectedRow
                            ? "#f5f5f5"
                            : "transparent",
                        color:
                          employee.id === selectedRow
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        "&:hover": {
                          backgroundColor: "white",
                          color: theme.palette.primary.main,
                          borderRadius: "20px",
                        },
                      }}
                    >
                      <TableCell onClick={() => detailPage()}>
                        {" "}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt={employee.firstname}
                            src={process.env.REACT_APP_API_URL+"\\"+employee.profile_picture}
                            sx={{ marginRight: 1 }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => detailPage()}>
                        <Typography>
                          {employee.firstname + " " + employee.lastname}
                        </Typography>
                      </TableCell>

                      <TableCell>{employee.position}</TableCell>
                      <TableCell>{employee.gender}</TableCell>
                      <TableCell>{employee.phone_number}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {employee.email}
                      </TableCell>
                      <TableCell>
                        <TableCell>
                          {new Date(
                            employee.employment_date
                          ).toLocaleDateString("en-GB")}
                        </TableCell>
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        <Tooltip title="Edit" arrow>
                          <IconButton onClick={() => handleEditClick(employee)}>
                            <EditIcon
                              sx={{ color: theme.palette.editIcon.main }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            onClick={() => handleDeleteClick(employee)}
                          >
                            <DeleteOutlineIcon
                              sx={{ color: theme.palette.deleteIcon.main }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="See Details" arrow>
                          <IconButton onClick={() => detailPage(employee.id)}>
                            <RemoveRedEyeIcon
                              sx={{ color: theme.palette.primary.main }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredEmployeeData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Paper>
        </Box>
      </Grid>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this leave request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteModal}
            type="submit"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentManagementTable;
