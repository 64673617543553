import * as React from "react";
import Avatar from "@mui/material/Avatar";

import Grid from "@mui/material/Grid";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import {
  SignUpFormContainer,
  SignInButton,
  FormBoxContainer,
  SignUpLinkButton,
  TextFieldStyled,
} from "../SignUp/SignUpStyle";
import { useEffect } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

export default function SignUp() {
  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = localStorage.getItem("user_info");
    if (userInfo) {
    }
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      const userData = {
        username: formData.get("firstName"),
        email: formData.get("email"),
        password: formData.get("password"),
        role: "admin",
        companyId: 1,
        company: "64ceed674885e1aceb8455cd",
      };

      console.log("User Data:", userData);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/register`,
          userData
        );
        // localStorage.setItem("user_info", JSON.stringify(userData));
        navigate("/login");
      } catch (error) {
        if (error.response) {
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          console.error("No Response:", error.request);
        } else {
          console.error("Request Error:", error.message);
        }
      }
    } catch (error) {
      console.error("Async Function Error:", error);
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid item justifyContent={"center"} xs={10} sm={8} md={6} lg={4} xl={4}>
        <SignUpFormContainer>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="secondary">
            Sign up
          </Typography>
          <FormBoxContainer
            id="Form-Submit"
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextFieldStyled
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldStyled
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldStyled
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldStyled
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <SignInButton type="submit" fullWidth variant="contained">
                  Sign Up
                </SignInButton>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <SignUpLinkButton to="/" variant="body2">
                    Already have an account? Sign in
                  </SignUpLinkButton>
                </Grid>
              </Grid>
            </Grid>
          </FormBoxContainer>
        </SignUpFormContainer>
      </Grid>
    </Grid>
  );
}
