import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";

import { theme } from "../../Theme";

const DashboardCard = ({ number, title, icon, background }) => {
  return (
    <Card
      style={{
        color: "white",
        borderRadius: "1rem",
        margin: ".3rem",

        height: "100%",
        background: background,
      }}
    >
      <CardActionArea>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box >{icon}</Box>
          <Box
            style={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {number}
            </Typography>
            <Typography variant="body2" color={theme.palette.secondary}>
              {title}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DashboardCard;
